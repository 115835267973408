import React from 'react';

class Placeholder extends React.Component{
    constructor(props){
        super(props);
        this.state={
            ready:false
        }
    }



    async componentDidMount(){
        // this.setState({ ready: true }); 

        setTimeout(function() {
            this.setState({ ready : true })
        }.bind(this), 100);
    }




        render(){
            return(
                <div style={{ visibility: this.state.ready ? 'visible' : 'hidden', color:'red' }}>
                    test test test
                </div>
            )
            

        }
}
export default Placeholder;