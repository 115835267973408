import './ColegiulEditorial.css';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faPencilAlt, faSearch, faGlasses, faUsers, faUser, faUserShield, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fab, faCheckSquare, faCoffee,faPencilAlt, faSearch, faGlasses, faUsers,faUser,faUserShield,faMapMarkerAlt)


class ColegiulEditorial extends React.Component{
    constructor(props){
        super(props);
        this.state={
            message:'success',
            // id_ultimul_articol:'',
            // link_ultimul_articol:''
        }
        // this.get_latest_revista=this.get_latest_revista.bind(this)
    }


    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;

        if(name==='firstName'){
            this.setState({
                firstName:value
            })
        }
        if(name==='lastName'){
            this.setState({
                lastName:value
            })
        }
        if(name==='phoneNumber'){
            this.setState({
                phoneNumber:value
            })
        }
        
        console.log(name,value)
    }



    componentDidMount(){
        
    }

    render(){
        let message=this.state.message

        
        if (message==='success' ){
            return (
                    <div className ="wrapper">
                        <div className="inner_wrapper">
                            
                            <div className="underline_container_redactie">
                                <h2 className="myicon"><FontAwesomeIcon icon="users" style={{color:'#1dbc60'}} /> </h2>

                                <div className="separator separator_colegiu">
                                    <h2>Colegiul Editorial</h2>
                                    <span className="underline_recenzare"/>
                                    <span className='steluta' style={{color:'#1dbc60'}}>✻</span>
                                    <span className="underline_recenzare"/>
                                </div>
                            </div>

                            <p className="paragraf_colegiu"> 
                                <h3 >Comitetul Științific</h3>
                                <br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                
                                <b>FEDERICA CORDANO</b> – Milano<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>FLORIN CURTA</b> – Gainesville, Florida<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>PIERRE DUPONT </b>– Lyon (France)<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>PETYA GEORGIEVA</b> – Sofia<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>MANFRED OPPERMANN</b> – Universitatea „Martin Luther”, Halle/Sa. (Germania)<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>ALEXANDER RUBEL</b> – Iași<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>CHRISTOF SCHULER</b> – München  <br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>GOCHA R. TSETSKHLADZE</b> – Llandrindod Wells, Powys, UK<br/><br/>


                                <h3>
                                    Redactor șef
                                </h3>
                                    <br/>
                                    <FontAwesomeIcon icon="user-shield" className="icon_check" />
                                    <b>LIVIA BUZOIANU</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/>
                                <FontAwesomeIcon icon="envelope" className="icon_check" />
                                <b> liviabuzoianu@yahoo.com</b> <br/><br/>

                                
                                <h3>Comitetul de Redacție</h3> <br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>CONSTANTIN CHERA</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>GABRIEL CUSTUREA</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>VALENTINA VOINEA</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/>
                                <FontAwesomeIcon icon="user" className="icon_check" />
                                <b>GABRIEL TALMAȚCHI</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/><br/>
                    

                                <FontAwesomeIcon icon="user-shield" className="icon_check" />
                                <b>Secretar de Redacție - IRINA SODOLEANU</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/><br/>

                                <h3>Informatică Editorială</h3><br/>
                                <b>VASILICA PODARIU</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/>
                                <b>MARIA BOLOCAN</b> – Muzeul de Istorie Naţională şi Arheologie Constanţa<br/><br/>

                                <h3>Administrare și Intreținere Site Web</h3><br/>
                                
                                <b>ADA MARCU</b> &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="envelope" className="icon_check" /> firstada2001@yahoo.com<br/>
                                <br/>
                                <h3>Adresa</h3><br/>
                                <FontAwesomeIcon icon="map-marker-alt" className="icon_check" />
                                Muzeul de Istorie Naţională şi Arheologie, Piaţa Ovidiu nr. 12, 900745, Constanţa, România.
                                Tel./Fax: 0040-241-618763<br/><br/>
                                
                                <h3>E-mail</h3><br/>
                                <FontAwesomeIcon icon="envelope" className="icon_check" />
                                revista.pontica@gmail.com, minaconstanta@gmail.com
                            </p>   
                        </div>
                    </div>

            );
        }
        else{
            return (
                <div>
                </div>
                );
        }
    }
    
}
export default ColegiulEditorial;

