import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios';
import './ArticoleAutori.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faNewspaper} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faNewspaper)

function ArticoleAutori() {
    
    
    const {autor} = useParams()
    const [articole, setArticole]=useState([])
    const [arhiva, setArhiva]=useState([])
    // const [toate_articolele, setToateArticolele] = useState([])
    



    useEffect(()=>{
        getAuthorArticles();
        getArhiva();
        // getToateArticolele();
    },[])

    async function getAuthorArticles(){
        await axios.get('/api/arhiva/getArticles/'+autor)
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
                // console.log('response.data.articole===',response.data.articole)
            }
            else{
                alert('Couldnt get blogs lists')
            }
        })
    }

    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
        .then(response => {
            if (response.data.success) {
                setArhiva(response.data.arhiva)
                console.log('lungime arhiva===',response.data.arhiva.length)
                // setCards(response.data.arhiva)
                // let arhiva_server=response.data.arhiva

                // setRevistaCurenta(arhiva_server[0].filename)
                // setSelectedCards(response.data.arhiva)

            } else {
                alert('Couldnt get blog`s lists')
            }
        })
    }


    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            let autor = lista_autori[i];
            autor=autor.trim()
            let vect = autor.split(' ')
            let prenume_autor=vect[0]


            // console.log('autor===',autor)
            // console.log('i=====',i)

            let restul_numelui=autor.replace(prenume_autor,'')
            // prenume_autor=prenume_autor.toLowerCase().charAt(0).toUpperCase()
            prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()
            if (prenume_autor.includes('-')){
                let vect=prenume_autor.split('-')

                prenume_autor=''
                for (let j=0; j<vect.length-1; j++){
                    prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                }
                prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()

                // prenume_autor=vect[0].charAt(0).toUpperCase()+'-'+vect[1].charAt(0).toUpperCase()
            }


            if (i==lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor+' '+restul_numelui}</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor}</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor +' '+ restul_numelui},</a>
                // let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{autor},</a>
                lista_finala.push(autor_link)
                // lista_finala.push(<span>,</span>)
            }
        }
        return lista_finala
    }


    function display_articole(){

        let listaArticole=[]
        let divarticol=''

        console.log('articole.length==',articole.length)

        // let divTitlu=''
        //     divTitlu=
        //     <h3>
        //         Numar Articole: {articole.length}
        //     </h3>
        //     listaArticole.push(divTitlu)
        // for (let i = 0; i < articole.length; i++) {
        //     const articol = articole[i];
        //         if (i%2==0){
        //             divarticol=
        //             <div className='divarticol_gri'>
        //                 <table className='titlu_articol'>
        //                     <tbody>
        //                         <tr>
        //                             <td>
        //                                 {/* <a className="titlu_articol" href={"/blog/post/"+articol['_id']}>
        //                                     {articol['titlu_articol']} 
        //                                 </a> */}
        //                                 <b><span>{articol['nr_articol']}</span></b>&nbsp;
        //                                 <a className='titlu_articol' href={"/blog/post/"+articol['_id']}>{articol['titlu_articol']}</a>

        //                             </td> 
        //                         </tr>
        //                     </tbody>
        //                 </table>

                        
        //             </div>
        //         }else{
                
        //         divarticol=
        //             <div className='divarticol'>
        //                 <table className='titlu_articol'>
        //                     <tbody>
        //                         <tr>
        //                             <td>
        //                                 {/* <a className="titlu_articol" href={"/blog/post/"+articol['_id']}>
        //                                     {articol['titlu_articol']} 
        //                                 </a> */}
        //                                 <b><span>{articol['nr_articol']}</span></b>&nbsp;
        //                                 <a className='titlu_articol' href={"/blog/post/"+articol['_id']}>{articol['titlu_articol']}</a>

        //                             </td> 
        //                         </tr>
        //                     </tbody>
        //                 </table>
        //             </div>
        //         }
            // listaArticole.push(divarticol)
        // }


    
        let divTitlu=<div className='articole_filtrate_container_gri'>
                    <div className='numar_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Nr. Articol</div>
                    <div className='titlu_volum_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Volum</div>
                    <div className='titlu_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Articol</div>
                    <div className='autori_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Autori</div>
                </div>
        listaArticole.push(divTitlu)


        if(articole.length>0 && arhiva.length>0){
            let articol=articole[0]
            for (let i = 0; i < articole.length; i++) {
                if(articole.length>1){
                    articol=articole[i]
                }
                
                let indice=articol.nr_articol-1
                var regExp = /^0[0-9].*$/
                if (regExp.test(indice)) //verific daca incepe cu 0
                indice =indice.substring(1) // scot 0 de la inceput
        
                // if (indice<1477){

                    let divarticol=
                        <div>
                            {i %2 == 0 && 
                            <div className='articole_filtrate_container'>
                                <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                                <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>

                                {articol.titlu_articol !== '' && 
                                <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                                {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                                    <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                                }

                                <table className='autori_articol_filtrat'>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='container_autor_filtrat'>
                                                    {display_autori(articol.autori)}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
        
                            {i %2 != 0 && 
                            <div className='articole_filtrate_container_gri'>
                                <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                                <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol.id_pontica].filename} </div>

                            {articol.titlu_articol !== '' && 
                                <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                            {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                            }
                                
                                <table className='autori_articol_filtrat'>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='container_autor_filtrat'>
                                                    {display_autori(articol.autori)}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    listaArticole.push(divarticol)
                // }
            }
        }
        return listaArticole;
    }




        if(articole.length>0){
        return(
                <div className='wrapper'>
                    <div className='inner_wrapper'>

                        <div className="underline_container_recenzare">
                            <h2>
                                <FontAwesomeIcon className='myicon' icon="newspaper" style={{color:'#1dbc60'}}/>
                            </h2>
                            
                            <div className="separator_recenzare separator_articole_autor">
                                <h2 className="procesul">Articole scrise de {autor}</h2>
                                <span className="underline_recenzare"/>
                                <span style={{color:'#1dbc60'}}>✻</span>
                                <span className="underline_recenzare"/>
                            </div>
                        </div>
                    </div>

                    <div>
                    <h3>
                        Numar Total Articole: <b>{articole.length}</b>
                    </h3>
                    </div>
                    <div>
                        {display_articole()}
                    </div>

                </div>
    )}
    else{
        return(
            <div>Loading......</div>
        )
    }

} export default ArticoleAutori


