import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from "react-redux";
import './CreatePage.css';

function CreatePage(props) {
    const user = useSelector(state => state.user);


    const [content, setContent] = useState("")
    const [an, setAn]=useState("")
    const [autori, setAutori] = useState("")
    const [totiAutorii, setTotiAutorii] = useState([])
    const [nr_articol, setNrArticol] = useState("")
    const [titlu_articol, setTitluArticol ] = useState("")
    const [nr_pontica, setNrPontica  ] = useState("")
    const [text_articol, setTextArticol] = useState("")
    const [ files, setFiles] = useState([])
    const [articol, setArticol]=useState({})
    const [filename_intreg, setFilenameIntreg ]=useState('')
    const [filename, setFilename ]=useState('')
    const [id, setId]= useState({})
    // const [writer, setWriter]=useState({})
    const [version, setVersion]=useState('')
    const [paginatie, setPaginatie] = useState('')
    const [id_pontica, setIdPontica]=useState('')
    const [titlu_articol_en, setTitluArticolEn]=useState('')
    const [sectiunea, setSectiunea ]=useState('')
    const [descriptori, setDescriptori  ]=useState('')
    const [limba, setLimba  ]=useState('')
    const [cuvinte_cheie, setCuvinteCheie  ]=useState('')
    const [cuvinte_cheie_en, setCuvinteCheieEn  ]=useState('')
    const [rezumat, setRezumat  ]=useState('')
    const [rezumat_en, setRezumatEn   ]=useState('')
    const [numar_pagini, setNumarPagini    ]=useState('')
    const [editura_volum, setEdituraVolum ]=useState('')
    const [localitatea_de_publicare, setLocalitateaDePublicare ]=useState('')
    const [issn_volum, setIssnVolum]=useState('')
    const [show_confirm_msg,setShowConfirmMsg]=useState(false)
    const [lista_autori_noi, setListaAutoriNoi]=useState([])
    const [autori_textarea, setAutoriTextarea]=useState('')
    const [articole, setArticole]=useState([])
    const [numar_articol, setNumarArticol]=useState('')


    const form = useRef();





    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setTotiAutorii(response.data.autori)
            }
        })
    }

    async function getArticles(){
        await axios.get('/api/arhiva/getArticles')
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
            }
        })
    }

    useEffect(()=>{
        getAuthors();
        getArticles();
    },[])

    useEffect(()=>{
        setNumarArticol(articole.length+1)
        console.log('articole.length====',articole.length)
    },[articole])


    useEffect (()=>{
        // let autorii_finali=autoriiDejaExistenti

        console.log('am intrat in show_confirm_msg')
        console.log('autori_textarea===',autori_textarea)

        if(document.getElementById('autori_textarea') != null)document.getElementById('autori_textarea').textContent=autori_textarea
    },[show_confirm_msg])

    useEffect(()=>{
        if(document.getElementById('autori_textarea') != null) document.getElementById('autori_textarea').innerText=autori_textarea
        // console.log('am modificat textarea')
    },[autori_textarea])



    useEffect(()=>{
        console.log('lista_autori_noi===',lista_autori_noi)
        
                //scot dublurile din autor.articole_autor
                
                for(let i=0; i<lista_autori_noi.length; i++){
                    let autor=lista_autori_noi[i]
                    autor.articole_autor=' '+autor.articole_autor

                    // console.log('inainte, articole_autor===',autor.articole_autor)
                    autor.articole_autor=Array.from(new Set(autor.articole_autor.split(','))).toString()
                    // console.log('dupa, articole_autor===',autor.articole_autor)

                    autor.articole_autor=autor.articole_autor.trim()

                    // let first_number =autor.articole_autor.split(',')[0]
                    

                    // let myvar = ' '+first_number
                    // let myvar2 = ', '+first_number
                    // let myregex=new RegExp(`\b${myvar}\b`, 'gi')
                    // let myregex2=new RegExp(`\b${myvar2}\b`, 'gi')

                    // autor.articole_autor=autor.articole_autor.replace(myregex,'')
                    // autor.articole_autor=autor.articole_autor.replace(myregex2,'')
                }
    },[lista_autori_noi])



    useEffect(()=>{
        if(articol != {} && articol != undefined && articole.length>1400){
        // if(articole.length>1400 ){
            console.log('articolul care trebuie creat ====',articol)

            setFilenameIntreg(articol.filename_intreg)
            setFilename(articol.filename)
            setId(articol._id)
            // setWriter(articol.writer)
            setVersion(articol.__v)
            setPaginatie(articol.paginatie)
            setIdPontica(articol.id_pontica)
            setNrPontica(articol.nr_pontica)
            setAn(articol.an)
            // setNrArticol(articol.nr_articol)
            setNrArticol(articole.length+1)
            setContent(articol.content)
            setAutori(articol.autori)
            setTitluArticol(articol.titlu_articol)
            setTitluArticolEn(articol.titlu_articol_en)
            setSectiunea(articol.sectiunea)
            setLimba(articol.limba)
            setCuvinteCheie(articol.cuvinte_cheie)
            setCuvinteCheieEn(articol.cuvinte_cheie_en)
            setRezumat(articol.rezumat)
            setRezumatEn(articol.rezumat_en)
            setNumarPagini(articol.numar_pagini)
            setDescriptori(articol.descriptori)
            
            // setTextArticol(articol.text_articol)
        }
    },[articol])


    useEffect(()=>{
        setNrArticol(articole.length+1)
    },[articole])
    
    const handleConfirmMsg = (e) => {
        e.preventDefault();
        setShowConfirmMsg(true);
    };

    const handleCancelMsg=(e)=>{
        e.preventDefault();
        setShowConfirmMsg(false);
    }

    function onSubmit(event){
        event.preventDefault();
        console.log('am intrat in submit')

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }
        let articol_creat={
            '_id':articol._id,
            'filename_intreg':filename_intreg,
            'filename':filename,
            'nr_articol':nr_articol,
            'nr_pontica':nr_pontica,
            'an':an,
            'autori':autori_textarea,
            'titlu_articol':titlu_articol,
            'content':content,
            'writer':user.userData._id,
            // '__v':version,
            'paginatie':paginatie,
            'id_pontica':id_pontica,
            'titlu_articol_en':titlu_articol_en,
            'sectiunea':sectiunea,
            'descriptori':descriptori,
            'limba':limba,
            'cuvinte_cheie':cuvinte_cheie,
            'cuvinte_cheie_en':cuvinte_cheie_en,
            'rezumat':rezumat,
            'rezumat_en':rezumat_en,
            'numar_pagini':numar_pagini,
        }




        

        axios.post('/api/blog/adaug_articol_in_articole_autor',lista_autori_noi)

        let text_articol_creat={
            'nr_articol':String(articol_creat.nr_articol),
            'text_articol':''
        }
        axios.post('/api/blog/adaug_text_articol', text_articol_creat)

        axios.post('/api/blog/createPost', articol_creat)
            .then(
                (()=>{
                    alert('Articolul a fost creat cu succes !');
                    console.log('articolul a fost creat cu succes')
                    window.location.reload();
                })()
            )
    }


    const onFilesChange = (files) => {
        setFiles(files)
    }


    function  onChangeDetails (event) {
        event.preventDefault();
        // setAn("");

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        let name=event.target.name
        let value=event.target.value

        // console.log('name===',event.target.name)
        // console.log('value===',event.target.value)

        if(name=='filename_intreg'){
            setFilenameIntreg(value)
        }
        else if(name=='filename'){
            setFilename(value)
        }
        else if (name=='nr_articol'){
            setNrArticol(value)
        }
        else if (name=='nr_pontica'){
            setNrPontica(value)
        }
        else if (name=='an'){
            setAn(value)
        }
        else if(name=='autori'){
            setAutori(value)
        }
        else if(name=='titlu_articol'){
            setTitluArticol(value)
        }
        else if(name=='content'){
            setContent(value)
        }
        // else if(name=='writer'){
        //     setWriter(value)
        // }
        else if(name=='paginatie'){
            setPaginatie(value)
        }
        else if(name=='id_pontica'){
            setIdPontica(value)
        }
        else if (name=='titlu_articol_en'){
            setTitluArticolEn(value)
        }
        else if (name=='sectiunea'){
            setSectiunea(value)
        }
        else if(name=='descriptori'){
            setDescriptori(value)
        }
        else if (name=='limba'){
            setLimba(value)
        }
        else if(name='cuvinte_cheie'){
            setCuvinteCheie(value)
        }
        else if(name=='cuvinte_cheie_en'){
            setCuvinteCheieEn(value)
        }
        else if(name=='rezumat'){
            setRezumat(value)
        }
        else if(name=='rezumat_en'){
            setRezumatEn(value)
        }
        else if(name=='numar_pagini'){
            setNumarPagini(value)
        }
        else if(name=='editura_volum'){
            setEdituraVolum(value)
        }
        else if(name=='localitatea_de_publicare'){
            setLocalitateaDePublicare(value)
        }
        else if(name=='issn_volum'){
            setIssnVolum(value)
        }
    }


    function handleSetState(mySetFunction, text_input, event) {
        // const value = event.currentTarget.value;
        console.log('mySetFunction===',mySetFunction)
        mySetFunction(text_input);
    }

    const onKeyDown = function(e, id_element, mySetFunction) {
        console.log('id_element===',id_element)
        if (e.keyCode === 8) {
            let text_input=''
            setTimeout(() => {
                text_input=document.getElementById(id_element).value
                // setDescriptori(text_input)
                handleSetState(mySetFunction, text_input, e)
            }, 100);
        }
    }


    async function handleCheckboxAutori(event,autor){
        console.log('adaug')
        if(event.target.checked){
            
            // numar_articol

            //inainte de a adauga numarul la finalul listei, trebuie sa verific ca nu exista deja in lista, pt a nu adauga dubluri
            // let lista_numere_articole = autor.articole_autor.split(',')
            
            
            // let exista_deja=false
            // for (let j=0; j<lista_numere_articole.length; j++){
            //     let numar_curent=lista_numere_articole[j]
            //     numar_curent=numar_curent.trim()
            //     if (numar_curent===numar_articol){
            //         exista_deja=true
            //     }
            // }
            // if (!exista_deja){
                if (autor.articole_autor==''){
                    autor.articole_autor=numar_articol
                }
                else{
                    autor.articole_autor+=', '+numar_articol
                }

                //scot dublurile din autor.articole_autor

                // console.log('inainte, articole_autor===',autor.articole_autor)
                // autor.articole_autor=Array.from(new Set(autor.articole_autor.split(','))).toString()
                // console.log('dupa, articole_autor===',autor.articole_autor)


                let pahar=[...lista_autori_noi]
                pahar.push(autor)
                setListaAutoriNoi(pahar)
            // }

            let vect=autor.nume_autor.split(',')
            let autor_de_adaugat= vect[1]+' '+vect[0]
            let text_casuta=document.getElementById('autori_textarea').textContent
            if (text_casuta==''){
                text_casuta+=autor_de_adaugat.toUpperCase()
            }
            else{
                text_casuta+=','+autor_de_adaugat.toUpperCase()
            }
            // let pahar2=[...autori_casuta]
            // pahar2.push(text_casuta)
            // setAutoriCasuta(pahar2)
            setAutoriTextarea(text_casuta)
        }
        else{
        console.log('scot')

            
            let pahar=[...lista_autori_noi]

            let contor=0
            console.log('autor debifat ====',autor)
            for (let j=0; j<pahar.length; j++){
                let autor_nou=pahar[j]
                if (autor_nou.id_autor===autor.id_autor){
                    
                    // console.log('autorul care trebuie scos din lista_autori_noi====',autor_nou)
                    // console.log('autor===',autor)
                    // console.log('autor_nou.id==',autor_nou.id_autor)
                    // console.log('autor.id==',autor.id_autor)




                    console.log('pahar inainte===',pahar)

                    // if(pahar.length>0){
                        // pahar.splice(j,1)
                        console.log('pahar dupa===',pahar)
                    // }




                    //1198, 1 ----> trebuie sa scot 1 de la sfarsit
                    // let re = /ab+c/i; // literal notation
                    // var regex = new RegExp("ReGeX" + testVar + "ReGeX");
                    // string.replace(regex, "replacement");

                    let myvar = ', '+numar_articol
                    let myvar2 = numar_articol
                    let myregex=new RegExp(`\\b${myvar}\\b`, 'gi')
                    let myregex2=new RegExp(`\\b${myvar2}\\b`, 'gi')

                    autor_nou.articole_autor=autor_nou.articole_autor.replace(myregex,'')
                    autor_nou.articole_autor=autor_nou.articole_autor.replace(myregex2,'')

                    console.log('autor_nou===',autor_nou)

                    contor++
                    break
                }
            }
            if(contor==0){
                // autor.articole_autor=autor.articole_autor.replace(', '+numar_articol,'')
                // autor.articole_autor=autor.articole_autor.replace(numar_articol,'')
                // pahar.push(autor)
                let myvar = ', '+numar_articol
                let myvar2 = numar_articol
                let myregex=new RegExp(`\\b${myvar}\\b`, 'gi')
                let myregex2=new RegExp(`\\b${myvar2}\\b`, 'gi')
                console.log('myregex2===',myregex2)
                autor.articole_autor=autor.articole_autor.replace(myregex,'')
                autor.articole_autor=autor.articole_autor.replace(myregex2,'')
                pahar.push(autor)
                console.log('am intrat pe varianta cu 0')
            }
            setListaAutoriNoi(pahar)
            let vect=autor.nume_autor.split(',')
            let autor_de_scos= vect[1]+' '+vect[0]
            autor_de_scos=autor_de_scos.toUpperCase()
            let text_casuta=document.getElementById('autori_textarea').textContent

            text_casuta=text_casuta.replace(','+autor_de_scos,'').replace(autor_de_scos,'')
            if(text_casuta[0]==','){
                text_casuta=text_casuta.substring(1)
            }
            setAutoriTextarea(text_casuta)
        }
    }


    async function clickPeAutor(autor){
        let myCheckbox=document.getElementById('autor'+autor.id_autor) 
        
        if (myCheckbox!=null){
            if(myCheckbox.checked){
                // console.log('unchecked')
                myCheckbox.click()
            }
            else{
                // console.log('checked')
                myCheckbox.click()
            }
        }
    }

    const CasutaAutori=totiAutorii.map( (autor)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_autor' id={'autor'+autor.id_autor} onChange= {(event)=>handleCheckboxAutori(event,autor)} type="checkbox" />
            <p className='autor' onClick={()=>clickPeAutor(autor)}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </p> 
        </div>
    })


        return (

            <div className='editare_articol_big_container'>
                <h1 className='titlu_mare_editare_articol'> Creare Articol</h1>
                {/* <QuillEditor
                    placeholder={"Start Posting Something"}
                    onEditorChange={onEditorChange}
                    onFilesChange={onFilesChange}
                /> */}
        
        {articole.length>0 &&
        
            <form ref={form}  onSubmit={handleConfirmMsg}>
                <div className='editare_articol_container'>

                    <div className='anul'>
                        <label className='anul_label' for="">Anul</label>
                        <input 
                            className='anul_input'
                            id='anul_input'
                            size="large"
                            htmlType="submit"
                            onChange={event=>setAn(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'anul_input',setAn)}}
                            defaultValue={articol.an}
                            type="text" 
                            name="an"
                        />
                    </div>

                    
                    <div className='numar_articol_div'>
                        <label for="">Numar Articol</label>
                        <input 
                            htmlType="submit"
                            id="numar_articol_input"
                            // placeHolder=''
                            onChange={event=>setNrArticol(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'numar_articol_input',setNrArticol)}}
                            defaultValue={articole.length+1}
                            type="text" 
                            name="nr_articol"
                        />
                    </div>

                    <div className='titlu_articol_div'>
                        <label for="">Titlu Articol</label>
                        <textarea 
                            rows='5'
                            cols='40'
                            // size="200"   
                            htmlType="submit"
                            id="titlu_articol_input"
                            // onChange={onChangeDetails}
                            onChange={event=>setTitluArticol(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'titlu_articol_input',setTitluArticol)}}
                            type="text" 
                            defaultValue={articol.titlu_articol}
                            name="titlu_articol"
                        >
                        </textarea>
                    </div>

                    <div className='titlu_articol_en_div'>
                        <label for="">Titlu Articol EN</label>
                        <textarea 
                            rows='5'
                            cols='40'
                            // size="200"   
                            htmlType="submit"
                            id="titlu_articol_en_input"
                            // onChange={onChangeDetails}
                            onChange={event=>setTitluArticolEn(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'titlu_articol_en_input',setTitluArticolEn)}}
                            type="text" 
                            defaultValue={articol.titlu_articol_en}
                            name="titlu_articol_en"
                        >
                        </textarea>
                    </div>

                    <div className='autori_div'>
                        <label for="">Autori</label>
                        <textarea
                            rows='5'
                            cols='40'
                            htmlType="submit"
                            id='autori_textarea'
                            type="text" 
                            name="autori"
                        />


                        <div className='criteriu_de_cautare'>
                            
                            <span className='autori_titlu'>Autori</span>
                            <div className='lista_toti_autorii' >
                                {CasutaAutori}
                            </div>
                        </div>
                    </div>


                    <div className='numar_pontica_div'>
                        <label for="">Numar Pontica</label>
                        <input
                            htmlType="submit"
                            id="numar_pontica_input"
                            placeHolder={'numarul ultimei reviste pontica este '+ articole[articole.length-1].nr_pontica}
                            onChange={event=>setNrPontica(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'numar_pontica_input',setNrPontica)}}
                            type="text" 
                            name="nr_pontica"
                        />
                    </div>

                    <div className='filename_div'>
                        <label for="">Filename</label>
                        <input
                            htmlType="submit"
                            id="filename_input"
                            placeHolder='exemplu: 52.pdf'
                            onChange={event=>setFilename(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'filename_input',setFilename)}}
                            type="text" 
                            name="filename"
                        />
                    </div>

                    <div className='id_pontica_div'>
                        <label for="">ID Pontica</label>
                        <input
                            htmlType="submit"
                            id="id_pontica_input"
                            onChange={event=>setIdPontica(event.target.value)}
                            onKeyDown={(event)=>{onKeyDown(event,'id_pontica_input',setIdPontica)}}
                            placeholder={'id-ul celei mai recente reviste este '+articole[articole.length-1].id_pontica}
                            type="text" 
                            name="id_pontica"
                        />
                    </div>


                    <div className='sectiunea_div'>
                        <label>Sectiunea</label>
                        <input
                            htmlType="submit"
                            id="sectiunea_input"
                            // onChange={onChangeDetails}
                            onChange={event=>{setSectiunea(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'sectiunea_input',setSectiunea)}}

                            // defaultValue={articol.sectiunea}
                            defaultValue={articol.sectiunea}
                            name="sectiunea"
                            type="text" 
                        />
                    </div>


                    <div className='descriptori_div'>
                        <label>Descriptori</label>
                        <input
                            id="descriptori_input"
                            htmlType="submit"
                            // onChange={onChangeDetails}
                            onChange={event=>{setDescriptori(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'descriptori_input',setDescriptori)}}
                            defaultValue={articol.descriptori}
                            name="descriptori"
                            type="text" 
                        />
                    </div>


                    <div className='limba_div'>
                        <label>Limba de Redactare</label>
                        <input
                            id="limba_input"
                            htmlType="submit"
                            // onChange={onChangeDetails}
                            onChange={event=>{setLimba(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'limba_input',setLimba)}}
                            defaultValue={articol.limba}
                            name="limba"
                            type="text" 
                        />
                    </div>


                    <div className='cuvinte_cheie_div'>
                        <label for="">Cuvinte Cheie</label>
                        <textarea
                            rows='5'
                            cols='40'
                            htmlType="submit"
                            id='cuvinte_cheie_textarea'
                            type="text" 
                            name="cuvinte_cheie"
                            onChange={event=>{setCuvinteCheie(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'cuvinte_cheie_textarea',setCuvinteCheie)}}
                            defaultValue={articol.cuvinte_cheie}
                        />
                    </div>


                    <div className='cuvinte_cheie_en_div'>
                        <label for="">Cuvinte Cheie EN</label>
                        <textarea
                            rows='5'
                            cols='40'
                            htmlType="submit"
                            id='cuvinte_cheie_en_textarea'
                            type="text" 
                            name="cuvinte_cheie_en"
                            onChange={event=>{setCuvinteCheieEn(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'cuvinte_cheie_en_textarea',setCuvinteCheieEn)}}
                            defaultValue={articol.cuvinte_cheie_en}
                        />
                    </div>


                    <div className='rezumat_div'>
                        <label>Rezumat</label>
                        <textarea
                            rows='5'
                            cols='40'
                            htmlType="submit"
                            id='rezumat_textarea'
                            type="text" 
                            name="rezumat"
                            onChange={event=>{setRezumat(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'rezumat_textarea',setRezumat)}}
                            defaultValue={articol.rezumat}
                        />
                    </div>


                    <div className='rezumat_en_div'>
                        <label>Rezumat EN</label>
                        <textarea
                            rows='5'
                            cols='40'
                            htmlType="submit"
                            id='rezumat_en_textarea'
                            type="text" 
                            name="rezumat_en"
                            onChange={event=>{setRezumatEn(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'rezumat_en_textarea',setRezumatEn)}}
                            defaultValue={articol.rezumat_en}
                        />
                    </div>


                    <div className='paginatie'>
                        <label>Paginatie in Volum</label>
                        <input
                            id="paginatie_input"
                            htmlType="submit"
                            onChange={event=>{setPaginatie(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'paginatie_input',setPaginatie)}}
                            defaultValue={articol.paginatie}
                            name="paginatie"
                            type="text" 
                        />
                    </div>


                    <div className='numar_pagini_div'>
                        <label>Numar Pagini</label>
                        <input
                            id="numar_pagini_input"
                            htmlType="submit"
                            onChange={event=>{setNumarPagini(event.target.value)}}
                            onKeyDown={(event)=>{onKeyDown(event,'numar_pagini_input',setNumarPagini)}}
                            defaultValue={articol.numar_pagini}
                            name="numar_pagini"
                            type="text" 
                        />
                    </div>



                    <div className='editare_submit_div' >
                        <button
                            htmlType="submit"
                            className="editare_submit"
                            onSubmit={handleConfirmMsg}>
                            SUBMIT
                        </button>
                    </div>
                </div>


                
            </form>

        }





                {show_confirm_msg && (
                    <div className='mesaj_confirmare'> 
                        <br/> Esti sigur ca vrei sa creezi articolul? {" "}
                        <button className='submit_raspuns' onClick={(event)=>onSubmit(event)}> 
                            DA
                        </button>

                        <button className='submit_raspuns' onClick={(event)=>handleCancelMsg(event)}> 
                            NU
                        </button>
                    </div> 
                )}
            </div>
            )
}

export default CreatePage
