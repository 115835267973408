import React, { useEffect, useState} from 'react'
import axios from 'axios';
import { Card, Col} from 'antd';
import './Arhiva.css'
import pdf_icon from '../../../images/pdf_icon.png';
import erihplus from '../../../images/erihplus.png';
import index_copernicus from '../../../images/index_copernicus.png';
import ez3 from '../../../images/ez3.png';
import scipio from '../../../images/scipio.png';
import ceeol from '../../../images/ceeol.png';
import digital from '../../../images/digital.png';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArchive} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faArchive)

function Arhiva() {
    const [arhiva, setArhiva]=useState([])
    const [articole, setArticole]=useState([])
    const [autori, setAutori]=useState([])
    const [autoriByNrRevista, setAutoriByNrRevista]=useState([])
    const [currentArticles, setCurrentArticles]=useState([])
    const [placeHolder, setplaceHolder]=useState([])
    const[activePage, setCurrentPage]=useState(1)
    const [cardsPerPage, setCardsPerPage] = useState(1)
    const[cards, setCards]=useState([])
    const [currentCard, setCurrentCard]=useState([])
    const [revistaCurenta, setRevistaCurenta]=useState('')
    const [idRevistaCurenta, setIdRevistaCurenta]=useState(0)
    const [currentIndex, setCurrentIndex]=useState(2)
    const [selectedVolumes, setSelectedVolumes]=useState([])
    const [selectedAutori, setSelectedAutori]=useState([])
    const [selectedCards, setSelectedCards]=useState([])
    const [listaArticoleFiltrate, setListaArticoleFiltrate]=useState([])
    const [filtruActiv, setFiltruActiv]=useState(false)
    const [searchInput, setSearchInput]=useState('')
    const [filtruReturnezToateArticoleleAutorului, setFiltruReturnezToateArticoleleAutorului]=useState(false)
    
    const listaRevisteCareNuAuAbrevieri=['1','47','22','23','26']
    
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function dynamicSortDescrescator(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    function compare( a, b ) {
        if ( parseInt(a.nr_pontica) < parseInt(b.nr_pontica) ){
            return -1;
        }
        if ( parseInt(a.nr_pontica) > parseInt(b.nr_pontica) ){
            return 1;
        }
        return 0;
    }

    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)
                    setCards(response.data.arhiva)
                    let arhiva_server=response.data.arhiva

                    setRevistaCurenta(arhiva_server[0].filename)
                    setSelectedCards(response.data.arhiva)
                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    }

    async function getArticles(){
        await axios.get('/api/arhiva/getArticles')
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
            }
        })
    }

    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setAutori(response.data.autori)
            }
        })
    }

    async function get_authors_by_id_revista(currentCards){
        if (currentCards.length===1){
            let id_revista=currentCards[0].id_pontica
            await axios.get('/api/arhiva/get_authors_by_id_revista/'+id_revista)
            .then(response=>{
                if (response.data.success){
                    setAutoriByNrRevista(response.data.autori_curenti)
                }
            })
        }
    }

    useEffect(() => {
        getArhiva();
        getArticles();
        getAuthors();
        let x = document.querySelector('active')
        if (x) {
            x.click()
        }
    },[])

    useEffect(() => {
        if(arhiva.length>0) {
            handlePageChange(activePage)
        }
    }, [activePage]);
    // }, [arhiva]);

    const indexOfLastCard = activePage*cardsPerPage;
    const indexOfFirstCard=indexOfLastCard-cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

    function handleCheckboxVolume(event,revista){
        if(event.target.checked){
            let pahar=[...selectedVolumes]
            pahar.push(revista)
            setSelectedVolumes(pahar)
        }
        else{
            let pahar=[...selectedVolumes]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_pontica == revista.id_pontica) {
                    pahar.splice(i,1);
                }
            }
            setSelectedVolumes(pahar)
        }
    }

    function clickPeVolum(revista){
        let myCheckbox=document.getElementById(revista.id_pontica)
        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }

    const CasutaVolume=arhiva.map( (revista)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_volum' id={revista.id_pontica} onChange= {(event)=>handleCheckboxVolume(event,revista)} type="checkbox" />
            <p className='volum' onClick={()=>clickPeVolum(revista)}> {revista.filename} </p> 
        </div>
    })

    useEffect (()=>{
        if (selectedAutori.length===0){
            setListaArticoleFiltrate([])
            setFiltruActiv(false)
        }
    },[selectedAutori])

    useEffect (()=>{
        if (selectedVolumes.length===0){
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else{
            setFiltruReturnezToateArticoleleAutorului(false)
        }
    },[selectedVolumes])

    function handleCheckboxAutori(event,autor){
        if(event.target.checked){
            let pahar=[...selectedAutori]
            pahar.push(autor)
            setSelectedAutori(pahar)
        }
        else{
            let pahar=[...selectedAutori]
            for (let i = 0; i < pahar.length; i++) {
                if (pahar[i].id_autor == autor.id_autor) {
                    pahar.splice(i,1);
                }
            }
            setSelectedAutori(pahar)
        }
    }

    function clickPeAutor(autor){
        let myCheckbox=document.getElementById('autor'+autor.id_autor)
        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }

    const CasutaAutori=autori.map( (autor)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_autor' id={'autor'+autor.id_autor} onChange= {(event)=>handleCheckboxAutori(event,autor)} type="checkbox" />
            <p className='autor' onClick={()=>clickPeAutor(autor)}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </p> 
        </div>
    })

    function handleRemoveRevista(revista){
        let pahar=[...selectedVolumes]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_pontica == revista.id_pontica) {
                    pahar.splice(i,1);
                }
            }
            setSelectedVolumes(pahar)
        document.getElementById(revista.id_pontica).click()
    }

    const revisteSelectate = selectedVolumes.map((revista) => {
        return <div className='view_all_si_checkbox'>
                <input className='check_volum' id={revista.id_pontica} onChange= {()=>handleRemoveRevista(revista)} type="checkbox" checked/>
                <p className='volum' onClick={()=>{handleRemoveRevista(revista)}}> {revista.filename} </p>
            </div>
    })

    function handleRemoveAutor(autor){
        let pahar=[...selectedAutori]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_autor == autor.id_autor) {
                    pahar.splice(i,1);
                }
            }
            setSelectedAutori(pahar)
        document.getElementById('autor'+autor.id_autor).click()
    }

    const autoriSelectati = selectedAutori.map((autor) => {
        return <div className='view_all_si_checkbox'>
            <input className='check_autor' id={'autor'+autor.id_autor}  onChange = {()=>handleRemoveAutor(autor)}  type="checkbox" checked/>
            <p className='autor' onClick={()=>handleRemoveAutor(autor)}> {autor.nume_autor} </p>
        </div>
    })

    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            let autor = lista_autori[i];
            autor=autor.trim()
            let vect = autor.split(' ')
            let prenume_autor=vect[0]

            let restul_numelui=autor.replace(prenume_autor,'')
            prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()
            if (prenume_autor.includes('-')){
                let vect=prenume_autor.split('-')
                prenume_autor=''
                for (let j=0; j<vect.length-1; j++){
                    prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                }
                prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()
            }

            if (i===lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor+' '+restul_numelui}</a>
                lista_finala.push(autor_link)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor +' '+ restul_numelui},</a>
                lista_finala.push(autor_link)
            }
        }
        return lista_finala
    }

    function displayArticole(id_revista,index){
        let listaArticole=[]
        let divarticol=''
        
        let divTitlu=
        <div className='divTitlu'>
            <table className='div_titlu_header_articol'>
                <tbody>
                    <tr>
                        <td className='div_titlu_header_articol'>
                            Titlu Articol
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='div_titlu_header_autori'>
                <tbody>
                    <tr>
                        <td>
                            Autori
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        listaArticole.push(divTitlu);

        for (let i = 0; i < articole.length; i++) {
            const articol = articole[i];
            if (articol['id_pontica']==id_revista ){
                if (i%2===0){
                    divarticol=
                        <div className='divarticol_gri'>
                            <table className='titlu_articol'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b><span>{articol['nr_articol']}</span></b>&nbsp;
                                            {articol.titlu_articol !== '' && 
                                            <a className='titlu_articol' href={'/blog/post/'+articol._id}>{articol.titlu_articol}</a>}
                                            {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                                            <a className='titlu_articol' href={'/blog/post/'+articol._id}>{articol.titlu_articol_en}</a>}
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                            <table className='autori'>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className='container_autor'>
                                                {display_autori(articol['autori'])}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }else{
                    divarticol=
                        <div className='divarticol'>
                            <table className='titlu_articol'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b><span>{articol['nr_articol']}</span></b>&nbsp;
                                            {articol.titlu_articol !== '' && 
                                            <a className='titlu_articol' href={'/blog/post/'+articol._id}>{articol.titlu_articol}</a>}
                                            {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                                            <a className='titlu_articol' href={'/blog/post/'+articol._id}>{articol.titlu_articol_en}</a>}

                                        </td> 
                                    </tr>
                                </tbody>
                            </table>

                            <table className='autori'>
                                <tbody>
                                    <tr>
                                        <div className='container_autor'>
                                            {display_autori(articol['autori'])}
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                }
                listaArticole.push(divarticol)
                setCurrentCard(id_revista)
            }
        }
        //daca e minimizat
        if(currentArticles.length === 0){
                setCurrentIndex(index)
                let listaArticole_fara_dubluri=listaArticole.filter(onlyUnique)
                setCurrentArticles([])
                setCurrentArticles(listaArticole_fara_dubluri)
                return listaArticole_fara_dubluri
        }
        //daca e maximizat
        else{
            //daca e acelasi buton
            if(index == currentIndex){
                setCurrentArticles([])
                return{}
            }
            //daca e alt buton
            else{
                let listaArticole_fara_dubluri=listaArticole.filter(onlyUnique)
                setCurrentIndex(index)
                setCurrentArticles(listaArticole_fara_dubluri)
                return{listaArticole_fara_dubluri}
            }
        }
    }

    function handleFiltru(){
        console.log('s-a aplicat filtrul')
        if(selectedAutori.length>0 && selectedVolumes.length===0){
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else if (selectedAutori.length===0  &&  selectedVolumes.length>0){
            // trebuie sa sortez descrescator selectedVolumes, adica de la pontica 52 la pontica 1
            selectedVolumes.sort(dynamicSortDescrescator("id_pontica"))
            setSelectedCards(selectedVolumes)
        }
        else{
            setSelectedCards(arhiva)
        }
        let lista_articole_filtrate = getFiltruAutori(selectedVolumes, selectedAutori)
        let lista_articole_filtrate_fara_dubluri=lista_articole_filtrate.filter(onlyUnique)
        lista_articole_filtrate_fara_dubluri.sort(compare)
        setListaArticoleFiltrate(lista_articole_filtrate_fara_dubluri)
        if( selectedAutori.length>0){
            setFiltruActiv(true)
        }else{
            setFiltruActiv(false)
        }
    }

    async function handleKeyDown(event){
        console.log('event handle key down')
        setSearchInput(event.target.value)
        if(event.keyCode===13 && event.shiftKey===false){
            event.preventDefault();
            console.log('am apasat enter')
            let submit_button=document.getElementsByClassName('search_link')[0];
            submit_button.click();
        }
    }

    async function handleChange(event){
        event.preventDefault();
        setSearchInput(event.target.value)
    }

    const despre= <div className='despre_pontica'>
                    <p>
                        <b>Revista PONTICA</b>, anuar al Muzeului de Istorie Naţională şi Arheologie Constanţa, are o apariţie neîntreruptă din anul 1968. Tematica revistei este orientată spre studii de arheologie, istorie veche şi medie.  În funcție de componența fiecărui număr în parte revista este structurată în subcapitole tematice, în cadrul fiecărui subcapitol articolele fiind ordonate cronologic: Historica, Archaeologica, Epigraphica, Christianitas, Auxiliaria, Nomismata, Recensiones etc..
                        În numerele revistei sunt analizate etape istorice importante în care zona pontică se defineşte ca zonă de contact între civilizaţiile egeo-mediteraneene şi transdanubiene.
                    </p>
                    <p>
                        În numerele revistei sunt analizate etape istorice importante în care spațiul pontic se definește ca zonă de contact între civilizațiile egeo-mediteraneene și transdanubiene. Aceste circuite privesc, pe rând, culturile neolitice și eneolitice; elemente de impact cultural greco-autohton; includerea zonei pontice în lumea romană ca etapă ce precedă extinderea autorității romane la nord de Dunăre și în spațiul intracarpatic; rolul creștinismului și romanitatea târzie. Sunt prezentate materiale epigrafice și numismatice; reliefuri laice și piese votive; elemente de arhitectură și inventar creștin; forme de organizare urbană etc. Semnatarii articolelor sunt nume consacrate în domeniu.
                    </p>       
                    <p>
                        Revista apare într-un tiraj de 250 de exemplare anual, fiecare număr conținând între 300 și 700 de pagini. Apariția în format fizic este dublată de publicarea online a fiecărui număr în parte. Difuzarea externă a revistei se face printr-un sistem de schimburi bibliografice instituit cu cca. 130 de instituții de profil din străinătate.    
                    </p>
                    <p>
                        Pontica este inclusă în baze de date internaționale (ERIH Plus, CEEOL, Index Copernicus, EZB – Electronic Journals Library, SCIPIO – Scientific Publishing & Information Online).
                    </p>
                    <a href="https://dbh.nsd.uib.no/publiseringskanaler/erihplus/periodical/info.action?id=482719">
                        <img className='iconite_descriere_arhiva' src={erihplus} alt=""/>
                    </a>
                    <a href="http://www.journals.indexcopernicus.com/Pontica,p10607,3.html">
                        <img className='iconite_descriere_arhiva' src={index_copernicus}alt=""/>
                    </a>
                    <a href="http://ezb.uni-regensburg.de/detail.phtml?bibid=AAAAA&colors=7&lang=en&jour_id=151817">
                        <img className='iconite_descriere_arhiva' src={ez3} alt=""/>
                    </a>
                    <a href="http://www.scipio.ro/web/pontica">
                        <img className='iconite_descriere_arhiva' src = {scipio} alt=""/>
                    </a>
                    <a href="https://www.ceeol.com/search/journal-detail?id=2688">
                        <img className='iconite_descriere_arhiva' src = {ceeol} alt=""/>
                    </a>
                    <a href="https://biblioteca-digitala.ro/?pub=231-pontica">
                        <img className='iconite_descriere_arhiva' src = {digital} alt="" width="250px"/>
                    </a>

                    <p>
                         În anul 2008 Revista PONTICA a fost clasificată de Consiliul Naţional al Cercetării Ştiinţifice din Învăţământul Superior (CNCSIS) în categoria B+. În anul 2018, odată cu reorganizarea <a className='linkuri_descriere_arhiva' href="http://cncsis.gov.ro/">CNCSIS</a>și constituirea Consiliului Național al Cercetării Științifice (CNCS), revista a fost reevaluată rămânând în <a className='linkuri_descriere_arhiva' href="http://www.cncs-nrc.ro/wp-content/uploads/2021/01/categorii.Reviste.CNCS_.2020.2.pdf">categoria</a> B, în care figurează și după evaluarea din anul 2020.
                        <br/>
                        <b>ISSN: 1013-4247</b>
                        <br/>
                        <b>e-ISSN: 2247-9341  </b>
                    </p>
                </div>


    function getFiltruAutori(){
        let lista_articole_filtrate=[]
        if(selectedVolumes.length>0){
            for (let i=0; i<selectedVolumes.length; i++){
                let revista_curenta=selectedVolumes[i];
        
                for(let j=0; j<articole.length; j++){
                    let articol_curent=articole[j]
                    if(parseInt(articol_curent.id_pontica) === parseInt(revista_curenta.id_pontica)){
                        
                        for (let k=0; k<selectedAutori.length; k++){
                            let autor_curent=selectedAutori[k];
                            
                            let lista_articole_autor = autor_curent.articole_autor.split(',')

                            for(let x=0; x<lista_articole_autor.length; x++){
                                if (parseInt(articol_curent.nr_articol) === parseInt(lista_articole_autor[x].trim())){
                                    // console.log('am gasit unul')
                                    lista_articole_filtrate.push(articol_curent)
                                }
                            }
                        }
                    }
                }
            }
        }
        else{
            console.log('am intrat pe ramura in care nu am niciun volum selectat')
            let lista_articole_autori=[]
            
            for (let i =0; i<selectedAutori.length; i++){
                let autor_curent=selectedAutori[i]
                let articolele_autorului =autor_curent.articole_autor
                articolele_autorului=articolele_autorului.split(',')

                for (let j=0; j<articolele_autorului.length; j++){
                    let nr_articol=articolele_autorului[j]
                    nr_articol=nr_articol.trim()
                    lista_articole_autori.push(articole[parseInt(nr_articol)-1])
                }
            }
            lista_articole_filtrate=lista_articole_autori
            console.log('lista_articole_filtwrate===',lista_articole_filtrate)
        }
        return lista_articole_filtrate
    }

    const renderFiltruAutoriFaraTitlu=listaArticoleFiltrate.map( (articol,index)=>{
        return <div>
                    {index %2 == 0 && 
                    <div className='articole_filtrate_container'>
                        <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                        { articol.id_pontica!==undefined &&
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&  <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}
                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}
                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}

                    {index %2 != 0 && 
                    <div className='articole_filtrate_container_gri'>
                        <div className='numar_articol_filtrat'>{articol.nr_articol}</div>
                        { articol.id_pontica!==undefined &&
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&  <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}
                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}
                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </div>
    })

    const renderFiltruAutori=(()=>{
        return  <div className = 'casuta_afisare_filtru' style={{  marginTop: 10 }}>
                    <div>{titluArticoleFiltrate()}</div>
                    <div>{renderFiltruAutoriFaraTitlu}</div>
                </div> 
    })
    

    const titluArticoleFiltrate =()=> {
        return  <div className='articole_filtrate_container_gri'>
                    <div className='numar_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Nr. Articol</div>
                    <div className='titlu_volum_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Volum</div>
                    <div className='titlu_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Articol</div>
                    <div className='autori_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Autori</div>
                </div>
    }

    const renderCards = selectedCards.map((revista, index) => {
        let revista_titlu='REVISTA ' + revista.filename.toUpperCase();
        return  <Col className='col' key={index} >
            <Card
                hoverable
                className='card'
            >
                <div className = 'casuta' style={{  overflowY: 'scroll', marginTop: 10 }}>
                        <span className='revista_titlu'>{revista_titlu}</span>
                        <div className='continut_card'>
                            <a href={"https://revistapontica.com/pdf/reviste/"+revista.filename+".pdf"}>
                                <img className='coperti' src={"https://revistapontica.com/pdf/coperti/"+revista.filename+".jpg"} alt="" />
                            </a>
                            <div className='detalii_volum_container'>
                                <span className='detalii_volum alb'> An <span className='span_de_la_server'>{revista.an}</span></span>
                                <span className='detalii_volum gri'> Numar Volum <span className='span_de_la_server'>{revista.nr_pontica}</span></span>
                                <span className='detalii_volum alb'> Numar Pagini <span className='span_de_la_server'> {revista.nr_pagini}</span></span>
                                <span className='detalii_volum gri'> Numar Articole <span className='span_de_la_server'> {revista.nr_articole}</span></span>
                                <span className='detalii_volum alb'> Numar Volum <span className='span_de_la_server'> {revista.nr_pontica_roman}</span></span>
                                <div className='gri'>
                                    <div className='descarca'><b>Descarca Volumul</b></div>
                                    <a href={"https://revistapontica.com/pdf/reviste/"+revista.filename+".pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                    </a>
                                </div>

                                <div className='alb'>
                                    <div className='descarca'><b>Descarca Sumarul</b></div>
                                        <a href={"https://revistapontica.com/pdf/sumar/" + "1" + revista.filename + " - Sumar.pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                        </a>
                                </div>

                                {  !listaRevisteCareNuAuAbrevieri.includes(revista.id_pontica)  && 
                                <div className='gri'>
                                    <div className='descarca'><b>Descarca Lista Abrevierilor</b></div>
                                        <a href={"https://revistapontica.com/pdf/sumar/" + "2" + revista.filename + " - Lista prescurtărilor.pdf"}>
                                        <span className='span_de_la_server'><img className='pdf_icon' src={pdf_icon} alt=""/></span>
                                        </a>
                                </div>}
                            </div>
                        </div>
                        <br/>
                        <button className='display_articole_button' type="submit" id={index} onClick={()=>displayArticole(revista.id_pontica,index) }>Afisati toate articolele</button>
                        { (currentCard===revista.id_pontica) ? currentArticles :placeHolder }
                </div>
            </Card>
        </Col>
    })

    const handlePageChange = ( pageNumber ) => {
        console.log( `active page is ${ pageNumber }` );
        setCurrentPage( pageNumber )
        if(arhiva.length>0){
            get_authors_by_id_revista(currentCards)
            let id_revista_curenta=arhiva.length-pageNumber+1
            setIdRevistaCurenta(id_revista_curenta)
            let revista_curenta=arhiva[arhiva.length-id_revista_curenta].filename
            setRevistaCurenta(revista_curenta)
        }
        else{
            console.log('arhiva e inca 0')
        }
    };

        return (
        <div className='bigContainer' style={{  margin: '2rem auto' }}>
            <div className='arhiva_header underline_container_redactie'>
                <div className='logo_and_title'>
                    <div className="separator linie">
                        <FontAwesomeIcon className= 'iconita_arhiva' icon="archive" style={{color:'#1dbc60'}} /> 
                        <h1 className="titlu_arhiva" >Arhiva Muzeului de Istorie Nationala si Arheologie Constanta</h1>
                        <span className="underline_recenzare"/>
                        <span style={{color:'#1dbc60'}}>✻</span>
                        <span className="underline_recenzare"/>
                    </div>
                </div>
            </div>

            <div className='smallContainer'>
                <div className='left_column'>
                    <div className='view_all_si_checkbox'>
                        <label className='checkbox'>
                        </label>
                    </div>

                    <span className='volume'>Volume</span>
                    <div className='pagination_container'>
                        <div className='casutavolume'>
                            {CasutaVolume}
                        </div>
                    </div>

                    <span className='volume_selectate_titlu'>Volume Selectate </span>
                    <div className='volume_selectate'>
                        {revisteSelectate}
                    </div>
                        <span className='autori_titlu'>Autori</span>
                        <div className='lista_toti_autorii' >
                            {CasutaAutori}
                        </div>
                        <span className='autori_selectati_titlu'>Autori Selectati  </span>
                        <div className='autori_selectati'>
                            {autoriSelectati}
                        </div>
                    <button className='filtreaza' onClick={()=>handleFiltru()}>
                        Aplica Filtre
                    </button>
                    <form className='search' onKeyDown={(event)=>handleKeyDown(event)}>
                        <button type="submit" className='search_button' onKeyDown={(event)=>handleKeyDown(event)}>
                            <a className='search_link' href={'/search/'+searchInput} >
                                Cautare in pdf
                            </a>
                        </button>
                        <input type="text" name='search_input' className='search_input' onChange={(event)=>handleChange(event)}/> 
                    </form>
                </div>

                <div className='CardsContainer'>
                    <div className='despreContainer'>
                            {despre}
                        <div className='despreOverlay'>
                        </div>
                    </div>
                    {selectedAutori.length===0 && renderCards}
                    {selectedAutori.length===0 && renderCards && filtruActiv===true}
                    {selectedAutori.length>0 &&  filtruActiv===false  &&  renderCards}
                    {selectedAutori.length>0  && filtruActiv ===true && renderFiltruAutori()}
                    { selectedAutori.length>0 && selectedVolumes.length===0 && filtruReturnezToateArticoleleAutorului ==true && filtruActiv===true }
                </div>
            </div>
        </div>
        )
} export default Arhiva


