import './LandingPage.css';
import React from 'react';
import Placeholder from './Placeholder.js';
import image11 from '../../../images/image11.jpg';
import image22 from '../../../images/image22.jpg';
import image33 from '../../../images/image33.jpg';
import image44 from '../../../images/image44.jpg';
import image55 from '../../../images/image55.jpg';
import image66 from '../../../images/image66.jpg';
// import coperta22 from '../../../images/coperta22.jpg';
import coperta33 from '../../../images/coperta33.jpg';




class LandingPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            mylist:[],
            contact:null,
            id:'',
            message:'success',
            contact_name:'',
            firstName:'',
            lastName:'',
            phoneNumber:'',
            mesaj_adaugare_contact:'',
            id_ultimul_articol:'',
            link_ultimul_articol:'',
            ready:false,
            img_loaded:false
        }
        // this.handleSubmit=this.handleSubmit.bind(this)
        // this.handleSubmitAddContact=this.handleSubmitAddContact.bind(this)
        this.get_latest_revista=this.get_latest_revista.bind(this)
    }


    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;

        if(name==='firstName'){
            this.setState({
                firstName:value
            })
        }
        if(name==='lastName'){
            this.setState({
                lastName:value
            })
        }
        if(name==='phoneNumber'){
            this.setState({
                phoneNumber:value
            })
        }
        
        console.log(name,value)
    }

    handleSubmitAddContact(e){
        e.preventDefault();
        var formData=new FormData(e.target)
        let firstName=formData.get('firstName')
        let lastName=formData.get('lastName')
        let phoneNumber=formData.get('phoneNumber')

        this.addContact(firstName,lastName,phoneNumber)
        this.setState({mesaj_adaugare_contact:'contactul a fost adaugat'})
    }


    get_latest_revista(){
        // var mylink=  "http://localhost:3000/blog/post/"+this.state.id_ultimul_articol
        let mylink="333333"
        // var result=<a className = "center_link" href={mylink}>test</a>
        var result = mylink
        console.log('mylink====',mylink)
        return result
    }

    async componentDidMount(){
        // this.setState({ ready: true });

        setTimeout(function() {
            this.setState({ ready : true })
        }.bind(this), 800);

        // await  axios.get('api/blog/getLastPost')
        // .then(response =>{
        //     if (response.data.success){
        //         console.log('am reusit getlastpost')
        //         var myid=response.data.mongo_result['_id']
        //         // console.log(myid)
        //         // console.log(this.state.id_ultimul_articol)
        //         this.setState({id_ultimul_articol:myid})
        //         this.setState({
        //             link_ultimul_articol:"http://localhost:3000/blog/post/"+myid,
        //             id_ultimul_articol:myid
        //         })
        //     }
        // })
    }

    doAnimation(){
        // document.getElementByID('image1').style.animation="spin2 4s linear infinite";
        // mydoc.style.animation='1.1s ease-in-out 0s 1 bounce_left';
        let mydoc=document.getElementById('image11');
        mydoc.id='image1';
    }



    




    render(){

        let message=this.state.message
        let myid=this.state.id_ultimul_articol
        let mylink="/blog/post/"+myid
        // let mylink="https://pontica.herokuapp.com/blog/post/"+myid
        // let mylink="https://pontica/"+myid
        {console.log('================== aicccccci',mylink)}

        
        if ((message==='success' ) && this.state.ready) {
            return (
                // <div className="container" style={{ visibility: this.state.ready ? 'visible' : 'hidden' }}>
                <div className="container">


                    {console.log('state==========',this.state.ready)}
                    {/* <Helmet> 
                        <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    </Helmet> */}
                    <div className="homepage_div">
                        <div className="homepage_subdiv_left">


                            <div className="image_div" id="image1" >
                                <a className="center_link" href="/arhiva">
                                    <img 
                                        // src={image11} style={this.state.img_loaded ? {} : {display: 'none'}}
                                        src={image11}
                                        alt=""
                                        className="image" 
                                        // onLoad={() => this.setState({img_loaded: true})}
                                        // onLoad={() =>this.setState({img_loaded:true}),this.doAnimation()}
                                    />
                                    <div className="overlay_left">
                                        <p className='detest'>Arhiva Pontica</p>
                                    </div>
                                </a>

                            </div>

                            <div className="image_div" id ="image2">
                                <a className="center_link" href="/blog">
                                <img src={image22} alt="" className="image"/>
                                <div className="overlay_left">
                                    <p>Articole</p>
                                </div>
                                </a>
                            </div>
                            
                            <div className="image_div" id ="image3">
                                <a className = "center_link" href="/">
                                    <img src={image33} alt="" className="image"/>
                                    <div className="overlay_left ">
                                        <p>Index</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="homepage_subdiv_center">
                            <div className='center_image_div'>
                                
                                <a className = "center_link" href="/arhiva">
                                    {/* <img src={coperta22} alt="" className="image_coperta image"/> */}
                                    <img src={coperta33} alt="" className="image_coperta image"/>

                                    {/* <div className="overlay_center"> */}
                                        {/* <div className='center_title_div'> */}
                                            {/* <p className='rand1 centru'>PONTICA</p> 
                                            <p className='rand2 centru'> LII</p>
                                            <p className='rand3 centru'>MUZEUL DE ISTORIE NAȚIONALĂ</p>
                                            <p className='rand4 centru'>ȘI ARHEOLOGIE </p>
                                            <p className='rand5 centru'>CONSTANȚA 2019</p> */}
                                        {/* </div> */}
                                    {/* </div> */}
                                </a>
                            </div>
                        </div>


                        <div className="homepage_subdiv_right">
                            <div className="image_div" id ="image4">
                                <a className = "center_link" href="/norme">
                                    <img src={image44} alt="" className="image"/>
                                    <div className="overlay_right ">
                                        <p>Norme de redactare</p>
                                    </div>
                                </a>
                            </div>

                            <div className="image_div" id ="image5">
                                <a className = "center_link" href="/redactia">
                                    <img src={image55} alt="" className="image"/>
                                    <div className="overlay_right ">
                                        <p>Colegiul Editorial</p>
                                    </div>
                                </a>
                            </div>

                            <div className="image_div" id ="image6">
                                <a className = "center_link" href="/recenzare">
                                    <img src={image66} alt="" className="image"/>
                                    <div className="overlay_right ">
                                        <p>Procesul de recenzare</p>
                                    </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
        else{
            return (<div></div>)
        }
    }
    
}
export default LandingPage;