import './ProcesRecenzare.css';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import pdf_icon from '../../../images/pdf_icon.png';
import digital from '../../../images/digital.png';
import ceeol from '../../../images/ceeol.png';
import erihplus from '../../../images/erihplus.png';
import index_copernicus from '../../../images/index_copernicus.png';
import ez3 from '../../../images/ez3.png';
import scipio from '../../../images/scipio.png';


library.add(fab, faCheckSquare, faCoffee,faPencilAlt, faSearch)


class ProcesRecenzare extends React.Component{
    constructor(props){
        super(props);
        this.state={
            message:'success',
            // id_ultimul_articol:'',
            // link_ultimul_articol:''
        }
        // this.get_latest_revista=this.get_latest_revista.bind(this)
    }


    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;

        if(name==='firstName'){
            this.setState({
                firstName:value
            })
        }
        if(name==='lastName'){
            this.setState({
                lastName:value
            })
        }
        if(name==='phoneNumber'){
            this.setState({
                phoneNumber:value
            })
        }
        
        console.log(name,value)
    }



    componentDidMount(){
        
    }

    render(){
        let message=this.state.message

        
        if (message==='success' ){
            return (
                    <div className ="wrapper">
                        <div className='inner_wrapper'>
                            <div className='epontica_links_container'>
                                <a href="https://dbh.nsd.uib.no/publiseringskanaler/erihplus/periodical/info.action?id=482719">
                                    <img className='iconite_descriere_arhiva' src={erihplus} alt=""/>
                                </a>
                                <a href="http://www.journals.indexcopernicus.com/Pontica,p10607,3.html">
                                    <img className='iconite_descriere_arhiva' src={index_copernicus}alt=""/>
                                </a>
                                <a href="http://ezb.uni-regensburg.de/detail.phtml?bibid=AAAAA&colors=7&lang=en&jour_id=151817">
                                    <img className='iconite_descriere_arhiva' src={ez3} alt=""/>
                                </a>
                                <a href="http://www.scipio.ro/web/pontica">
                                    <img className='iconite_descriere_arhiva' src = {scipio} alt=""/>
                                </a>
                            </div>

                            <div className='epontica_links_container'>
                                <a href="https://www.ceeol.com/search/journal-detail?id=2688">
                                        <img className='iconite_descriere_arhiva' src={ceeol} alt=""/>
                                    </a>
                                    <a href="https://biblioteca-digitala.ro/?pub=231-pontica">
                                        <img className='iconite_descriere_arhiva' src = {digital} alt="" width="250px"/>
                                </a>
                            </div>


                            <p className='paragraf_epontica'>
                                <h2><FontAwesomeIcon icon="pencil-alt" style={{color:'#1dbc60'}} /> Demersul editorial și procesul peer-review</h2>

                                Modul de atragere a propunerilor pentru articole diferă între revistă și numerele sale suplimentare. Pentru revistă invitația se publică pe site-ul instituției, împreună cu normele de redactare, adresa de trimitere a contribuțiilor și termenul limită de depunere a lucrărilor. Aceleași informații sunt anunțate și la lansarea invitației de participare la Sesiunea Internațională Pontica organizată anual de Muzeul de Istorie Națională și Arheologie Constanța, dat fiind faptul că multe din articolele publicate în revistă sunt susținute mai întâi în cadrul acestei sesiuni, sub formă de prezentări.
                                <br/> 
                                <br/> 
                                Pentru numerele suplimentare, de obicei organizate tematic, coordonatorii alcătuiesc o listă cu autori ale căror domenii de interes coincid cu tema respectivului volum. Se trimit invitații pentru publicare, însoțite de un scurt argument menit să descrie demersul editorial și motivele inițierii lui, ambele în limba română și în engleză. Se trimit de obicei până la trei circulare în vederea asigurării unei prezențe cât mai variate, numeroase și relevante în cadrul respectivelor numere.
                                <br/>
                                <br/>
                                După centralizarea articolelor trimise spre publicare, acestea sunt înaintate spre recenzare unor personalități din domeniul științific, atât din țară cât și din străinătate. Recenzorii sunt selectați, în funcție de specializare și domeniul de interes, dintr-o baza de date întocmită anterior și îmbogățită constant de către membrii colegiului de redacție. Propunerile de recenzare sunt însoțite de un formular tip, pe care evaluatorul trebuie să îl completeze și să îl retrimită redacției. Procesul de recenzare este dublu anonimizat, astfel încât numele autorilor evaluați să nu fie cunoscut de către recenzor, iar acesta să rămână, la rândul său, anonim în fața autorilor. 
                                <br/>
                                <br/>
                                În general pentru fiecare articol trebuie să fie prezentate două referate de recenzare. În procesul de recenzare se au în vedere calitatea științifică a articolelor, veridicitatea și susținerea informațiilor oferite de autori, apartenența cercetărilor prezentate la aria geografică de interes științific, respectarea normelor de publicare impuse de revistă, calitatea limbii străine în care a fost scris articolul și rezumatul său etc.  
                                <br/>
                                <br/>
                                Urmează prezentarea recenziilor într-o ședință a colegiului de redacție, precum și a propunerilor de acceptare sau respingere a contribuțiilor științifice pentru numărul respectiv. Respingerea unui articol, împreună cu motivația aferentă sunt aduse la cunoștința autorilor prin e-mail. În același mod sunt transmise modificările, corecturile pe care autorii trebuie să le aplice articolului pentru publicarea lui. 
                                <br/>
                                <br/>
                                                            După stabilirea sumarului volumului începe etapa de colaționare, tehno-redactare, informatică editorială, toate desfășurate de către membrii colegiului de redacție și de către cei ai echipei tehnice. Tipărirea produsului final se face în colaborare externă în tiraje de 250-300 de volume, dublate de varianta online publicată pe site-ul www.revistapontica.com.
                            </p>
                        </div>    
                    </div>
            );
        }
        else{
            return (
                <div>
                </div>
                );
        }
    }
}
export default ProcesRecenzare;