import React, { useEffect, useState} from 'react'
import pdf_icon from '../../../images/pdf_icon.png';
import erihplus from '../../../images/erihplus.png';
import index_copernicus from '../../../images/index_copernicus.png';
import ez3 from '../../../images/ez3.png';
import scipio from '../../../images/scipio.png';
import './Epontica.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Epontica(){
    return (
        <div className='wrapper'>
      

        </div>
    )
}
export default Epontica