import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './BlogPage.css'
import Pagination from "react-js-pagination";

function BlogPage() {
        
    const [arhiva, setArhiva]=useState([])
    const [articole, setArticole]=useState([])
    const [autori, setAutori]=useState([])
    const [activePage, setCurrentPage]=useState(1)
    const [articolePerPage, setArticolePerPage] = useState(100)
    const [selectedVolumes, setSelectedVolumes]=useState([])
    const [selectedAutori, setSelectedAutori]=useState([])
    const [listaArticoleFiltrate, setListaArticoleFiltrate]=useState([])
    const [filtruActiv, setFiltruActiv]=useState(false)
    const [filtruReturnezToateArticoleleAutorului, setFiltruReturnezToateArticoleleAutorului]=useState(false)
    const [nrArticolGasit, setNrArticolGasit]=useState('')

    const indexOfLastArticol = activePage*articolePerPage;
    const indexOfFirstArticol=indexOfLastArticol-articolePerPage;
    const currentArticole = articole.slice(indexOfFirstArticol, indexOfLastArticol);    

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function compare( a, b ) {
        if ( parseInt(a.nr_pontica) < parseInt(b.nr_pontica) ){
            return -1;
        }
        if ( parseInt(a.nr_pontica) > parseInt(b.nr_pontica) ){
            return 1;
        }
        return 0;
    }

    async function getArticles(){
        await axios.get('/api/arhiva/getArticles')
        .then(response=>{
            if (response.data.success){
                setArticole(response.data.articole)
            }
        })
    }

    async function getArhiva(){
        axios.get('/api/arhiva/getArhiva')
        .then(response => {
            if (response.data.success) {
                setArhiva(response.data.arhiva)
            } else {
                alert('Couldnt get blog`s lists')
            }
        })
    }

    useEffect(()=>{
        getArticles();
        getArhiva();
        getAuthors();
    },[])

    useEffect (()=>{
        if (selectedAutori.length===0 &&  selectedVolumes.length===0){
            setListaArticoleFiltrate([])
            setFiltruActiv(false)
        }
        if (selectedVolumes.length===0){
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else{
            setFiltruReturnezToateArticoleleAutorului(false)
        }
    },[selectedAutori, selectedVolumes])

    function display_autori(autori_cu_virgula){
        autori_cu_virgula=autori_cu_virgula.replace(', ',',').replace(',  ',',')
        let lista_autori=autori_cu_virgula.split(',')
        let lista_finala=[]

        for (let i = 0; i < lista_autori.length; i++) {
            let autor = lista_autori[i];
            autor=autor.trim()
            let vect = autor.split(' ')
            let prenume_autor=vect[0]
            let restul_numelui=autor.replace(prenume_autor,'')
            prenume_autor = prenume_autor.charAt(0).toUpperCase()+prenume_autor.substring(1).toLowerCase()

            if (prenume_autor.includes('-')){
                let vect=prenume_autor.split('-')
                prenume_autor=''
                for (let j=0; j<vect.length-1; j++){
                    prenume_autor+=vect[j].charAt(0).toUpperCase()+vect[j].substring(1).toLowerCase()+'-'
                }
                prenume_autor+=vect[vect.length-1].charAt(0).toUpperCase()+vect[vect.length-1].substring(1).toLowerCase()
            }
            if (i===lista_autori.length-1){
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor+' '+restul_numelui}</a>
                lista_finala.push(autor_link)
            }
            else{
                let autor_link=<a className='autor_link' href={"/articole/autori/"+autor}>{prenume_autor +' '+ restul_numelui},</a>
                lista_finala.push(autor_link)
            }
        }
        return lista_finala
    }

    async function handleSubmit(event){
        event.preventDefault();
        var formData=new FormData(event.target)
        let myinput=formData.get('search_input')
        await axios.get('/api/arhiva/getSearch/'+myinput)
            .then(response => {
                if (response.data.success) {
                    // setSearchResult(response.data.search_result)
                } else {
                    alert('Couldnt get search data')
                }
            })
    }

    useEffect (()=>{
        if(nrArticolGasit!=''){
            let mylist=[]
            mylist.push(articole[parseInt(nrArticolGasit-1)])
            setListaArticoleFiltrate(mylist)
            setFiltruActiv(true)
        }
    },[nrArticolGasit])

    async function handleSubmitNrArticol(event){
        event.preventDefault();
    
        var formData=new FormData(event.target)
        let myinput=formData.get('search_input')

        for (let i =0; i<articole.length; i++){
            let articol=articole[i];
            let indice=articol.nr_articol
                var regExp = /^0[0-9].*$/
                if (regExp.test(indice)) //verific daca incepe cu 0
                indice =indice.substring(1) // scot 0 de la inceput

            if (indice===myinput){
                setNrArticolGasit(myinput)
                console.log('myinput=====',myinput)
                return articol[indice]
            }
        }
    }

    async function getAuthors(){
        await axios.get('/api/arhiva/getAuthors')
        .then(response=>{
            if (response.data.success){
                setAutori(response.data.autori)
            }
        })
    }

    function handleCheckboxVolume(event,revista){
        if(event.target.checked){
            let pahar=[...selectedVolumes]
            pahar.push(revista)
            setSelectedVolumes(pahar)
        }
        else{
            let pahar=[...selectedVolumes]
            for (let i = pahar.length - 1; i >= 0; --i) {
                if (pahar[i].id_pontica == revista.id_pontica) {
                    pahar.splice(i,1);
                }
            }
            setSelectedVolumes(pahar)
        }
    }

    function clickPeVolum(revista){
        let myCheckbox=document.getElementById(revista.id_pontica)
        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
            
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }

    function handleRemoveRevista(revista){
        let pahar=[...selectedVolumes]
        for (let i = pahar.length - 1; i >= 0; --i) {
            if (pahar[i].id_pontica == revista.id_pontica) {
                pahar.splice(i,1);
            }
        }
        setSelectedVolumes(pahar)
        document.getElementById(revista.id_pontica).click()
    }

    function handleFiltru(){
        console.log('s-a aplicat filtrul')
        if(selectedAutori.length>0 && selectedVolumes.length===0){
            console.log('varianta 1')
            setFiltruReturnezToateArticoleleAutorului(true)
        }
        else if (selectedAutori.length===0  &&  selectedVolumes.length>0){
            console.log('varianta 2')
            // setSelectedCards(selectedVolumes)
            setFiltruActiv(true)
        }
        // setFiltruActiv(true)

        let lista_articole_filtrate = getFiltruAutori()
        let lista_articole_filtrate_fara_dubluri=lista_articole_filtrate.filter(onlyUnique)
        lista_articole_filtrate_fara_dubluri.sort(compare)
        setListaArticoleFiltrate(lista_articole_filtrate_fara_dubluri)

        if( selectedAutori.length>0){
            setFiltruActiv(true)
        }else if(selectedVolumes.length>0){
            setFiltruActiv(true)
        }
        else{
            setFiltruActiv(false)
        }
    }

    function getFiltruAutori(){
        let lista_articole_filtrate=[]

        if(selectedVolumes.length>0 && selectedAutori.length===0){
            setFiltruActiv(true)

            for (let i=0; i<selectedVolumes.length; i++){
                let revista_curenta=selectedVolumes[i];

                for(let j=0; j<articole.length; j++){
                    let articol_curent=articole[j]
                    if(parseInt(articol_curent.id_pontica) === parseInt(revista_curenta.id_pontica)){
                        lista_articole_filtrate.push(articol_curent)
                    }
                }
            }
            setListaArticoleFiltrate(lista_articole_filtrate)
        }
        else if(selectedVolumes.length>0 && selectedAutori.length>0){
            for (let i=0; i<selectedVolumes.length; i++){
                let revista_curenta=selectedVolumes[i];
        
                for(let j=0; j<articole.length; j++){
                    let articol_curent=articole[j]
                    if(parseInt(articol_curent.id_pontica) === parseInt(revista_curenta.id_pontica)){
                        
                        for (let k=0; k<selectedAutori.length; k++){
                            let autor_curent=selectedAutori[k];
                            let lista_articole_autor = autor_curent.articole_autor.split(',')
                            for(let x=0; x<lista_articole_autor.length; x++){
                                if (parseInt(articol_curent.nr_articol) === parseInt(lista_articole_autor[x].trim())){
                                    lista_articole_filtrate.push(articol_curent)
                                }
                            }
                        }
                    }
                }
            }
        }
        else{
            let lista_articole_autori=[]
            for (let i =0; i<selectedAutori.length; i++){
                let autor_curent=selectedAutori[i]
                let articolele_autorului =autor_curent.articole_autor
                articolele_autorului=articolele_autorului.split(',')

                for (let j=0; j<articolele_autorului.length; j++){
                    let nr_articol=articolele_autorului[j]
                    nr_articol=nr_articol.trim()
                    lista_articole_autori.push(articole[parseInt(nr_articol)-1])
                }
            }
            lista_articole_filtrate=lista_articole_autori
        }
        return lista_articole_filtrate
    }

    function handleCheckboxAutori(event,autor){
        if(event.target.checked){
            let pahar=[...selectedAutori]
            pahar.push(autor)
            setSelectedAutori(pahar)
        }
        else{
            let pahar=[...selectedAutori]
            for (let i = 0; i < pahar.length; i++) {
                if (pahar[i].id_autor == autor.id_autor) {
                    pahar.splice(i,1);
                }
            }
            setSelectedAutori(pahar)
        }
    }

    function clickPeAutor(autor){
        let myCheckbox=document.getElementById('autor'+autor.id_autor)
        if(myCheckbox.checked){
            console.log('unchecked')
            myCheckbox.click()
        }
        else{
            console.log('checked')
            myCheckbox.click()
        }
    }

    function handleRemoveAutor(autor){
    let pahar=[...selectedAutori]
        for (let i = pahar.length - 1; i >= 0; --i) {
            if (pahar[i].id_autor == autor.id_autor) {
                pahar.splice(i,1);
            }
        }
        setSelectedAutori(pahar)
    document.getElementById('autor'+autor.id_autor).click()
    }

    function handlePaginationClick(){
        console.log('am dat click pe paginatie')
    }

    const handlePageChange = ( pageNumber ) => {
        console.log( `active page is ${ pageNumber }` );
        setCurrentPage( pageNumber )

        if(arhiva.length>0){
            // console.log('active page ====',activePage)
        }
        else{
            console.log('arhiva e inca 0')
        }
    };

    const CasutaVolume=arhiva.map( (revista)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_volum' id={revista.id_pontica} onChange= {(event)=>handleCheckboxVolume(event,revista)} type="checkbox" />
            <p className='volum' onClick={()=>clickPeVolum(revista)}> {revista.filename} </p> 
        </div>
    })

    const revisteSelectate = selectedVolumes.map((revista) => {
        return <div className='view_all_si_checkbox'>
                <input className='check_volum' id={revista.id_pontica} onChange= {()=>handleRemoveRevista(revista)} type="checkbox" checked/>
                <p className='volum' onClick={()=>{handleRemoveRevista(revista)}}> {revista.filename} </p>
            </div>
    })

    const CasutaSearchDupaNrArticol=(()=>{
        return <div>
                <form className='search' onSubmit={handleSubmitNrArticol}>
                    <button className='search_button' onSubmit = {handleSubmitNrArticol}>
                        Nr Articol
                    </button>
                    <input type="text" name='search_input' className='search_input'/> 
                </form>
                </div>
    })

    const leftColumn=(()=>{
        return <div className='left_column'>
        <div className='view_all_si_checkbox'>
            <label className='checkbox'>
            </label>
        </div>

        <span className='volume'>Volume</span>
        <div className='pagination_container'>
            <div className='casutavolume'>
                {CasutaVolume}
            </div>
        </div>

        <span className='volume_selectate_titlu'>Volume Selectate </span>
        <div className='volume_selectate'>
            {revisteSelectate}
        </div>
            <span className='autori_titlu'>Autori</span>
            <div className='lista_toti_autorii' >
                {CasutaAutori}
            </div>
            <span className='autori_selectati_titlu'>Autori Selectati  </span>
            <div className='autori_selectati'>
                {autoriSelectati}
            </div>
        <button className='filtreaza' onClick={()=>handleFiltru()}>
            Aplica Filtre
        </button>

        {CasutaSearchDupaNrArticol()}

        <div className='pagination_div'>
            <Pagination 
                activePage={ activePage }
                itemsCountPerPage={ 100 }
                totalItemsCount={ articole.length }
                pageRangeDisplayed={ 100 }
                onChange={ handlePageChange } 
                onClick={handlePaginationClick}
            />
        </div>
    </div>
    })

    const CasutaAutori=autori.map( (autor)=>{
        return <div className='view_all_si_checkbox'> 
            <input className='check_autor' id={'autor'+autor.id_autor} onChange= {(event)=>handleCheckboxAutori(event,autor)} type="checkbox" />
            <p className='autor' onClick={()=>clickPeAutor(autor)}> {autor.nume_autor.split(',')[0].toUpperCase()+', '+autor.nume_autor.split(',')[1]} </p> 
        </div>
    })

    const autoriSelectati = selectedAutori.map((autor) => {
        return <div className='view_all_si_checkbox'>
            <input className='check_autor' id={'autor'+autor.id_autor}  onChange = {()=>handleRemoveAutor(autor)}  type="checkbox" checked/>
            <p className='autor' onClick={()=>handleRemoveAutor(autor)}> {autor.nume_autor} </p>
        </div>
    })

    const titluArticole =()=> {
        return  <div className='articole_filtrate_container_gri'>
                    <div className='numar_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Nr</div>
                    <div className='titlu_volum_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Volum</div>
                    <div className='titlu_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Articol</div>
                    <div className='autori_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Autori</div>
                </div>
    }

    const articoleFaraTitlu=articole.map( (articol,index)=>{
        return <div>
                    {index %2 == 0 && 
                    <div className='articole_filtrate_container'>
                        <div className='numar_articol_filtrat'>{articol.nr_articol}</div>

                        { articol.id_pontica!==undefined &&
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&  <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}
                        
                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }
                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}

                    {index %2 != 0 && 
                    <div className='articole_filtrate_container_gri'>
                        <div className='numar_articol_filtrat'>{articol.nr_articol}</div>

                        {  articol.id_pontica!==undefined && 
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&  <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}

                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </div>
    })

    const titluArticoleFiltrate =()=> {
        return  <div className='articole_filtrate_container_gri'>
                    <div className='numar_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Nr.</div>
                    <div className='titlu_volum_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Volum</div>
                    <div className='titlu_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Titlu Articol</div>
                    <div className='autori_articol_filtrat' style={{fontWeight:'bold', fontSize:'20px'}}>Autori</div>
                </div>
    }

    const renderArticole=(()=>{
        return  <div className = 'casuta_afisare_filtru' style={{  marginTop: 10 }}>
                    <div>{titluArticole()}</div>
                    <div>{articoleFaraTitlu}</div>
                </div> 
    })

    const articoleCurenteFaraTitlu=currentArticole.map( (articol,index)=>{
        return <div>
                    {index %2 == 0 && 
                    <div className='articole_filtrate_container'>
                        <a className= 'numar_articol_filtrat' href={'/blog/post/'+articol._id}> <div>{articol.nr_articol}</div></a>
                        
                        {  articol.id_pontica!==undefined && 
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&  <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}

                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}

                    {index %2 != 0 && 
                    <div className='articole_filtrate_container_gri'>
                        <a className='numar_articol_filtrat' href={'/blog/post/'+articol._id}> <div>{articol.nr_articol}</div></a>
                        
                        {  articol.id_pontica !=undefined && 
                        (arhiva.length-parseInt(articol.id_pontica)) >= 0  &&
                        <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-parseInt(articol.id_pontica)].filename} </div>}

                        {articol.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol}</div></a></div>}

                        {articol.titlu_articol==='' && articol.titlu_articol_en !=='' &&
                            <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol._id}><div>{articol.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
    })

    const renderArticoleCurente=(()=>{
        return  <div className = 'casuta_afisare_filtru' style={{  marginTop: 10 }}>
            <div>{titluArticole()}</div>
            <div>{articoleCurenteFaraTitlu}</div>
        </div> 
    })

    const renderFiltruAutoriFaraTitlu=listaArticoleFiltrate.map( (articol_filtrat,index)=>{
        return <div>
                    {index %2 == 0 && 
                    <div className='articole_filtrate_container'>
                        <a className='numar_articol_filtrat' href={'/blog/post/'+articol_filtrat._id}> <div>{articol_filtrat.nr_articol}</div></a>
                        <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol_filtrat.id_pontica].filename} </div>

                        {articol_filtrat.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol}</div></a></div>}

                        {articol_filtrat.titlu_articol==='' && articol_filtrat.titlu_articol_en !=='' &&
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol_filtrat.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}

                    {index %2 != 0 && 
                    <div className='articole_filtrate_container_gri'>
                        <a className='numar_articol_filtrat' href={'/blog/post/'+articol_filtrat._id}> <div>{articol_filtrat.nr_articol}</div></a>
                        <div className='titlu_volum_filtrat'> {arhiva[arhiva.length-articol_filtrat.id_pontica].filename} </div>
                        
                        {articol_filtrat.titlu_articol !== '' && 
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol}</div></a></div>}

                        {articol_filtrat.titlu_articol==='' && articol_filtrat.titlu_articol_en !=='' &&
                        <div className='titlu_articol_filtrat'><a href={'/blog/post/'+articol_filtrat._id}><div>{articol_filtrat.titlu_articol_en}</div></a></div>
                        }

                        <table className='autori_articol_filtrat'>
                            <tbody>
                                <tr>
                                    <td >
                                        <div className='container_autor_filtrat'>
                                            {display_autori(articol_filtrat.autori)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </div>
    })

    const renderFiltruAutori=(()=>{
        return  <div className = 'casuta_afisare_filtru' style={{  marginTop: 10 }}>
                    <div>{titluArticoleFiltrate()}</div>
                    <div>{renderFiltruAutoriFaraTitlu}</div>
                </div> 
    })

    return (
        <div className='container_blog' style={{ width: '80%', margin: '3rem auto' }}>
                {leftColumn()}
                {/* {filtruActiv===false && renderArticole()} */}
                {filtruActiv===false && renderArticoleCurente()}

                {console.log('filtru activ == ',filtruActiv)}
                { filtruActiv ===true && renderFiltruAutori()}
                { selectedAutori.length>0 && selectedVolumes.length===0 && filtruReturnezToateArticoleleAutorului ==true && filtruActiv===true }
            </div>
    )
} export default BlogPage


