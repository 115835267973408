import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import Auth from "../hoc/auth";
// pagini
import LandingPage from "./views/LandingPage/LandingPage.js";

import NormeRedactare from "./views/GenericPage/NormeRedactare.js";
import ProcesRecenzare from "./views/GenericPage/ProcesRecenzare.js";
import ColegiulEditorial from "./views/GenericPage/ColegiulEditorial.js";
import Epontica from "./views/Epontica/Epontica.js";

import LoginPage from "./views/LoginPage/LoginPage.js";
import RegisterPage from "./views/RegisterPage/RegisterPage.js";
import NavBar from "./views/NavBar/NavBar";
import Footer from "./views/Footer/Footer"

import PostPage from "./views/PostPage/PostPage";
import BlogPage from "./views/BlogPage/BlogPage";
import Arhiva from "./views/Arhiva/Arhiva.js";
import CreateBlogPage from "./views/BlogPage/Section.js/CreatePage";
import EditBlogPage from "./views/BlogPage/Section.js/EditPage";

import EditAutor from "./views/SectiuneEditareAutori/EditAutor";

import CreateVolum from "./views/Arhiva/CreateVolum";
import SectiuneEditareVolume from "./views/Arhiva/SectiuneEditareVolume";
import EditVolum from "./views/Arhiva/EditVolum";

import ArticoleAutori from "./views/ArticoleAutori/ArticoleAutori";
import SectiuneEditareArticole from "./views/SectiuneEditareArticole/SectiuneEditareArticole";
import SectiuneEditareAutori from "./views/SectiuneEditareAutori/SectiuneEditareAutori";
import Search from "./views/Search/Search";
import PaginaAdmin from './views/PaginaAdmin/PaginaAdmin';
import IdleTimerContainer from './IdleTimerContainer/IdleTimerContainer.js';



function App() {
  return (
    <Suspense fallback={(<div>Loading...</div>)}>
      <NavBar />
      <IdleTimerContainer/>
      
      <div  >
        <Switch>
          <Route exact path="/" component={Auth(LandingPage, null)} />
          <Route exact path="/login" component={Auth(LoginPage, false)} />
          <Route exact path="/register" component={Auth(RegisterPage, false)} />
          <Route exact path="/blog" component={Auth(BlogPage, null)} />
          
          <Route exact path="/blog/create" component={Auth(CreateBlogPage, null)} />
          <Route exact path="/blog/edit/:nr_articol" component={Auth(EditBlogPage, null)} />
          <Route exact path="/blog/editare_articole/" component={Auth(SectiuneEditareArticole, null)} />
          <Route exact path="/blog/editare_autori/" component={Auth(SectiuneEditareAutori, null)} />
          <Route exact path="/blog/editare_autori/:id_autor" component={Auth(EditAutor, null)} />


          <Route exact path="/admin/" component={Auth(PaginaAdmin, null)} />
          <Route exact path="/blog/post/:postId" component={Auth(PostPage, null)} />
          <Route exact path="/norme" component={Auth(NormeRedactare, null)} />
          <Route exact path="/recenzare" component={Auth(ProcesRecenzare, null)} />
          <Route exact path="/redactia" component={Auth(ColegiulEditorial, null)} />
          <Route exact path="/epontica" component={Auth(Epontica, null)} />
          <Route exact path="/arhiva" component={Auth(Arhiva, null)} />

          <Route exact path="/arhiva/create" component={Auth(CreateVolum, null)} />
          <Route exact path="/arhiva/edit/" component={Auth(SectiuneEditareVolume, null)} />
          <Route exact path="/arhiva/edit/:id_pontica" component={Auth(EditVolum, null)} />

          <Route exact path="/articole/autori/:autor" component={Auth(ArticoleAutori, null)} />
          <Route exact path="/search/:searchInput" component={Auth(Search, null)} />
          {/* <Route exact path="/arhiva/?search_input=searchInput" component={Auth(Search, null)} /> */}
        </Switch>
      </div>
      <Footer/>
    </Suspense>
  );
}

export default App;
