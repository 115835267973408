import IdleTimer from 'react-idle-timer';
import React, {useRef} from 'react';
import { useDispatch } from "react-redux";
import { logoutUser } from "../../_actions/user_actions";

function IdleTimerContainer(){

    const dispatch = useDispatch();

    const idleTimerRef = useRef(null)


    const onIdle = ()=>{
        console.log('user is idle')
        // alert('ai fost scos datorita inactivitatii')
        dispatch(logoutUser())
    }



    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={20*60*1000} onIdle={onIdle}> </IdleTimer>
        </div>
    )
}
export default IdleTimerContainer