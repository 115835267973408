import './Footer.css';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faPencilAlt, faAngleRight, faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fab, faCheckSquare, faCoffee,faPencilAlt,faAngleRight, faHome, faPhone, faEnvelope,faFacebook)


class Footer extends React.Component{    

    constructor(props){
        super(props);
        this.state={
            mylist:[],
            contact:null,
            id:'',
            message:'success',
            contact_name:'',
            firstName:'',
            lastName:'',
            phoneNumber:'',
            mesaj_adaugare_contact:'',
            ready:false
        }
        
    }

    async componentDidMount(){
        setTimeout(function() {
            this.setState({ ready : true })
        }.bind(this), 1200);
    }

    render(){
        let message=this.state.message
        if (message==='success'){
            return (
            <div className="footer" style={{ visibility: this.state.ready ? 'visible' : 'hidden' }}>
                <div className="footer_links">
                    <div className="footer_links_inner_div">
                        <div className="logo_si_despre">
                            <div className="logo_footer">
                                {/* <img src="../../../images/logo50.png" alt=""/> */}
                                <img src={require('../../../images/logo.png')}  />
                            </div>
                            <div className="despre">
                                <p>Revista PONTICA, anuar al Muzeului de Istorie Naţională şi Arheologie Constanţa, are o apariţie neîntreruptă din anul 1968. Tematica revistei este orientată spre studii de arheologie, istorie veche şi medie. Sumarul este structurat pe Studii şi comunicări, Cercetări arheologice, Note, Numismatică, Recenzii şi Note bibliografice.</p>
                            </div>
                        </div>

                        <div className="linkuri_utile">
                            <h3>LINK-URI UTILE</h3>
                            
                            <a className='link_util' href="https://www.minac.ro/"><FontAwesomeIcon icon="angle-right" className="arrow" /> Ore de vizita</a>


                            <a className= 'link_util' href="https://www.minac.ro/muzeul.html"><FontAwesomeIcon icon="angle-right" className="arrow" />  Despre Noi</a>
                            <a className='link_util' href="https://www.minac.ro/calendar-evenimente.html"><FontAwesomeIcon icon="angle-right" className="arrow" />  Evenimente</a>
                            <a className='link_util' href="https://www.minac.ro/cercetare.html"><FontAwesomeIcon icon="angle-right" className="arrow" />  Cercetare</a>
                            <a className='link_util' href="https://www.minac.ro/muzee-si-cetati.html"><FontAwesomeIcon icon="angle-right" className="arrow" />  Muzee</a>
                            <a className='link_util' href="https://www.minac.ro/pontica-2021---organizare.html"><FontAwesomeIcon icon ="angle-right" className="arrow" /> Sesiunea Pontica</a>
                            
                        </div>

                        <div className="informatii_contact">
                            <h3>INFORMATII CONTACT</h3>
                            <p className='link_util' href="http://google.com"><FontAwesomeIcon icon="home" style={{color:'#1dbc60', marginRight:'8px'}} className='arrow'/>
                                <div className='footer_link_text'>Piața Ovidiu nr. 12, Cod poștal 900745, Constanța</div>
                            </p>
                            <p className='link_util' href="http://google.com"><FontAwesomeIcon icon="phone" style={{color:'#1dbc60', marginRight:'10px'}} className='arrow'/>+4 0241 614 562</p>
                            <p  className='link_util' href="http://google.com"><FontAwesomeIcon icon="phone" style={{color:'#1dbc60', marginRight:'10px'}} className='arrow'/>+4 0241 618 763</p>
                            <p className='link_util' href="http://google.com"><FontAwesomeIcon icon="envelope" style={{color:'#1dbc60', marginRight:'10px'}} className='arrow'/><div className='footer_link_email'>revista.pontica@gmail.com, minaconstanta@gmail.com</div></p>
                            
                        </div>

                        <div className="facebook_like">
                            <h3>FACEBOOK LIKE</h3>
                            <a className='link_util' href="https://www.facebook.com/minaconstanta"><FontAwesomeIcon icon={["fab","facebook-square"]} style={{ marginRight:'10px', color:'#1dbc60'}} className='arrow'/>Urmariti-ne pe Facebook</a>
                        </div>
                    </div>
                </div>

                <div className="copyright">
                    <div className="copyright_text">
                        2021 &copy; Revista Pontica
                    </div>
                </div>
                    
            </div>
            );
        }
        else{
            return (
                <div>
                </div>
        );
        }
    }
    
}
export default Footer;