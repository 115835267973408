import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './SectiuneEditareVolume.css'




    
    
function SectiuneEditareVolume() {


    const [arhiva, setArhiva]=useState([])


    useEffect(()=>{
        axios.get('/api/arhiva/getArhiva')
        .then( (res)=>{
            if(res.data.success){
            setArhiva(res.data.arhiva)
            console.log('res.arhiva===',res.data.arhiva)
            }
            else{
                console.log('erroare')
            }
        })
    },[])


        const casutaVolume=arhiva.map((revista,index) =>{
        return <div className='casuta_editare_autor'>
            <span className='numar_autor'>{revista.id_pontica}</span>
            <a  className='link_editare_autor' href={'/arhiva/edit/'+revista.id_pontica}><span>{revista.filename}</span></a>
        </div>
        // <span> {autor.nume_autor}</span>
    })






    return (
            <div>
                {arhiva.length>50 && 
                <div className='editare_autori_titlu'>
                    <h1>Alege volumul pe care vrei sa-l editezi:</h1>
                    <h2>Total Volume: {arhiva.length}</h2>
                </div>
                }
                <div className='editare_autori_container'>
                    <div className='casuta_editare_autori'>
                        {arhiva.length>50 && casutaVolume}
                    </div>
                </div>
            </div>
    )
} export default SectiuneEditareVolume




    



    
// function SectiuneEditareAutori() {

//     const [autori, setAutori] = useState([])



//     useEffect(()=>{
//         axios.get('/api/arhiva/getAuthors')
//         .then( (res)=>{
//             if(res.data.success){
//             setAutori(res.data.autori)
//             console.log('res.autori===',res.data.autori)
//             }
//             else{
//                 console.log('erroare')
//             }
//         })
//     },[])



//     const casutaAutor=autori.map((autor,index) =>{
//         return <div className='casuta_editare_autor'>
//             <span className='numar_autor'>{autor.id_autor}</span>
//             <a  className='link_editare_autor' href={'/blog/editare_autori/'+autor.id_autor}><span>{autor.nume_autor}</span></a>
//         </div>
//         // <span> {autor.nume_autor}</span>
//     })




//     return (
//         <div>
//             {autori.length>570 && 
//             <div className='editare_autori_titlu'>
//                 <h1>Alege autorul pe care vrei sa-l editezi:</h1>
//                 <h2>Total Autori: {autori.length}</h2>
//             </div>
//             }

//             <div className='editare_autori_container'>

//                 <div className='casuta_editare_autori'>

//                     {autori.length>570 && casutaAutor}
//                 </div>
//             </div>
//         </div>
//     )
// } export default SectiuneEditareAutori


