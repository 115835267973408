import './NormeRedactare.css';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fab, faCheckSquare, faCoffee,faPencilAlt)


class NormeRedactare extends React.Component{
    constructor(props){
        super(props);
        this.state={
            message:'success',
            // id_ultimul_articol:'',
            // link_ultimul_articol:''
        }
        // this.get_latest_revista=this.get_latest_revista.bind(this)
    }


    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;

        if(name==='firstName'){
            this.setState({
                firstName:value
            })
        }
        if(name==='lastName'){
            this.setState({
                lastName:value
            })
        }
        if(name==='phoneNumber'){
            this.setState({
                phoneNumber:value
            })
        }
        
        console.log(name,value)
    }



    componentDidMount(){
        
    }

    render(){
        let message=this.state.message

        
        if (message==='success' ){
            return (
                    <div className ="wrapper">
                        <div className="inner_wrapper">
                            <h2><FontAwesomeIcon icon="pencil-alt" style={{color:'#1dbc60'}} /> Norme de redactare pentru revista PONTICA</h2>
                            <div className="underline_container">
                                <span className="underline"/>
                                <span style={{color:'#1dbc60'}}>✻</span>
                                <span className="underline"/>
                            </div>
                            <p> 
                            
                                Este preferabil ca lucrările, redactate în limba română sau într-o limbă de circulaţie internaţională, să fie procesate pe computer în conformitate cu normele de redactare ale revistei şi să fie trimise redacţiei pe adresa <b>revista.pontica@gmail.com</b>
                                , pe care se va ține toată corespondența privitoare la articolul propus spre publicare.<br/>
                                Textul articolului şi ilustraţiile vor fi însoţite de rezumatul şi cuvintele cheie, în limba română și în limba engleză.<br/>
                                Pentru redactarea textului folosind programul Microsoft Word, formatul paginii este A4. Iniţializarea paginii este următoarea: sus - 1,5 cm; jos - 7,3 cm; stânga -3 cm; dreapta - 4,6 cm; antet - 1,3 cm. <br/>
                                Lumina paginii este de 13,5x19,5 cm.<br/> 
                                Textul, tabelele şi imaginile (planşe, desene, fotografii) trebuie să fie încadrate în lumina paginii.

                            <br/>
                            <br/>
                            <b>a) Pentru text: </b> 
                            <br/>
                            
    
                            
                            Fontul folosit este <i>Palatino Linotype</i> cu urmatoarele dimensiuni

                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>titlu articol</i> - 12,5 puncte, condensat 0,4, boldat, cu majuscule;</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>autor</i> - 12 puncte, condensat 0,4;</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>text</i> - 10 puncte, extins 0,2;</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>note de subsol</i> - 8,5 puncte, extins 0,4;</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>rezumat</i> - 10 puncte, extins 0,2;</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>bibliografie</i> - 9 puncte, extins 0,2;</li>
                            <br/>

                            <b>b) Ilustraţia:</b>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  Ilustraţia va fi furnizată în format .jpeg sau .tiff, rezoluţia minimă 300 dpi. În cazul în care nu este posibilă scanarea conform cerinţelor formulate, autorul poate trimite ilustraţia în original (desene, fotografii, diapozitive). </li>
                            
                            <br/>
                            <b>c) Notele infrapaginale</b>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />  <i>Notele infrapaginale</i>, numerotate continuu, vor fi redactate după sistemul anglo-saxon: autor, anul apariţiei lucrării, pagina citata - după caz figură, tabelul - (ex.: PIPPIDI 1967, p. 84, fig. 3/4), urmând ca în final să se anexeze o listă bibliografică ordonată alfabetic, după numele autorilor, şi cronologic, pentru fiecare autor în parte. </li>
                            <br/>
                            <b>d) Lista bibliografica</b>
                            <br/>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" /><i>Lista bibliografica</i> va fi redactata in forma:
                            <br/>
                            <i>Pentru carte de autor</i>: nume autor, anul apariţiei, titlul lucrării, volum (număr
                            si titlu), ediţie, serie (colecţie), numărul în cadrul seriei sau colecţiei, locul apariţiei. </li>
                            <br/>
                            
                            <i>Exemple:</i>
                            <br/>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" /> BOJILOV, GJIUZELEV 2004 - Ivan Bojilov, Vasil Gjuzelev, <i>Istorija na
                            Dobroudja, Vol. 2, Sredneyekovîe,</i> Veliko Târnovo (2004).</li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" /> PÁUNESCU 2005 - Anca Păunescu, <i>Oraşul de Floci, un oraş dispărut din
                            Muntenia meridională,</i> Târgovişte, 2005.</li>
                            <br/>
                            <i>Pentru articole</i>: nume autor, anul apariţiei, titlul articolului, numele
                            publicaţiei sau al volumului colectiv, tom, număr, locul apariţiei si editorul - pentru volume colective, paginile articolului.
                            <br/>
                            <br/>
                            <i>Exemple:</i>
                            <br/>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />COJOCARU 2009 – Victor Cojocaru, <i>Zum Proxenie in den griechischen Städten des pontischen Raumes,</i> Pontica 42 (2009), p. 349-374. </li>
                            <li><FontAwesomeIcon icon="check-square" className="icon_check" />ROBU 2013 - Adrian ROBU, <i>Sanctuare și relații între cetăți în lumea elenistică: exemplul cetăților Chalcedon și Byzantion, în: Florina Panait Bîrzescu et alii (eds), Poleis în Marea Neagră: relații interpontice și producții locale, București, 2013,</i> p. 143-157. </li>
                            <br/>
                            <b>e) Titlurile </b> periodicelor, volumelor, cataloagelor, <i>corpora</i>, enciclopediilor se vor
                            abrevia după <i>Normele de întocmire a manuscriselor</i>, Anexele 2 si 3 din SCIVA 46,
                            1995, 3 - 4, p. 356 - 370. Orice altă abreviere în afara celor ce se regăsesc în
                            lucrarea citată va fi prezentată si explicit, la sfârşitul listei bibliografice.<br/>
                            Redacția își rezervă dreptul de a respinge orice referire cu caracter defăimător sau neștiințific la adresa unor persoane sau instituții științifice de profil. 
                            <br/>
                            <br/>
                            <a className='descarca_sablon' href="https://minac.ro/word/sablon_articol.doc">Descarca sablon articol</a>
                            </p>
                        </div>
                    </div>

            );
        }
        else{
            return (
                <div>
                </div>
                );
        }
    }
    
}
export default NormeRedactare;

