import React, { useEffect, useState } from 'react'
import axios from 'axios';
import pdf_icon from '../../../images/pdf_icon.png';
import './PostPage.css'

function PostPage(props) {
    const [post, setPost] = useState([])
    const [arhiva, setArhiva]=useState([])
    const [text_articol, setTextArticol]=useState([])
    const postId = props.match.params.postId;

    useEffect(() => {
        const variable = { postId: postId }

        axios.post('/api/blog/getPost', variable)
            .then(response => {
                if (response.data.success) {
                    setPost(response.data.post)

                    axios.get('/api/blog/getText/'+response.data.post.nr_articol)
                    .then(res => {
                        if (res.data.success) {
                            setTextArticol(res.data.text_articol.text_articol)
                        } else {
                            alert('Couldnt get text')
                        }
                    })
                } else {
                    alert('Couldnt get post')
                }
            })
    }, [postId])

    //daca vreau sa obtin textul articolului
    // useEffect(() => {
    //     // const variable = { postId: postId }
    //     post != undefined && axios.get('/api/blog/getText/'+post.nr_pontica)
    //         .then(response => {
    //             if (response.data.success) {
    //                 setTextArticol(response.data.text_articol)
    //             } else {
    //                 alert('Couldnt get text')
    //             }
    //         })
    // }, [])

    useEffect(() => {
        // getArticles();
        axios.get('/api/arhiva/getArhiva')
            .then(response => {
                if (response.data.success) {
                    setArhiva(response.data.arhiva)
                    // setCards(response.data.arhiva)
                } else {
                    alert('Couldnt get blog`s lists')
                }
            })
    },[])



    if(post.id_pontica!==undefined && arhiva!== undefined){
        let indice_arhiva=-1
        for (let i = 0; i < arhiva.length; i++) {
            var element = arhiva[i];
            
            // {console.log('post.id_pontica==',post.id_pontica)}
            // {console.log('element.id_pontica===',element.id_pontica)}
            
            if (parseInt(element.id_pontica)===parseInt(post.id_pontica)){
                indice_arhiva=i
                break;
            }
        }
        let titlu=''
        if (indice_arhiva!==-1){
            titlu='REVISTA '+(arhiva[indice_arhiva].filename).toUpperCase()
        }
        
        let link_descarcare='https://revistapontica.com/pdf/articole/'+ post.filename
        

        if(indice_arhiva!==-1){
            return(
                <div className="post_page">
                    <div className='casuta_post'>
                        <span className='revista_titlu'>{titlu}</span>

                        {post.titlu_articol!=='' && indice_arhiva!==-1 && <h2 className='titlu_articol_post'>{post.titlu_articol}</h2>}
                        {post.titlu_articol_en !=='' && indice_arhiva!==-1 && <h2 className='titlu_articol_post'>{post.titlu_articol_en}</h2>}

                        <div className='coperta_si_detalii_articol'>
                            { arhiva!==undefined && indice_arhiva!==-1 
                            && <img className='coperti' src={"https://revistapontica.com/pdf/coperti/"+arhiva[indice_arhiva].filename+".jpg"}  /> }

                            <div className='detalii_volum_container_articol'>
                                <div className='detalii_volum_container_articol_mic'>
                                    
                                    {arhiva.length>50 && console.log('indice_arhiva=====',indice_arhiva)}


                                    {arhiva.length>50 && console.log('editura_nume arhiva===',arhiva[indice_arhiva].editura_nume)}

                                    {arhiva.length>50 && <span className='detalii_de_la_server_articol'>Editura Volum <span className='span_articol'>{arhiva[indice_arhiva].editura_nume}</span></span>}

                                    {arhiva.length>50 && <span className='detalii_de_la_server_articol'>Localitatea De Publicare <span className='span_articol'>{arhiva[indice_arhiva].editura_localitate}</span></span>}

                                    {arhiva.length>50 && <span className='detalii_de_la_server_articol'>ISSN Volum<span className='span_articol'>{arhiva[indice_arhiva].issn}</span></span>}

                                    <div className='descarca_articol'><b>Descarca Articolul</b>
                                        <span className='span_articol' ><a href={link_descarcare}>
                                            <img className='pdf_icon' src={pdf_icon}/>
                                        </a></span>
                                    </div>
                                    {/* <p>{text_articol}</p> */}
                                </div>
                            </div>

                        </div>

                        <div className='detalii_articol'>
                            <div >
                                <div className='first_div'>Autori</div>
                                <div className='second_div'> {post.autori}</div>
                            </div>

                            {post.sectiunea !== '' && <div>
                                <div className='first_div'>Sectiunea</div> 
                                <div className='second_div'>{post.sectiunea}</div>
                            </div>}

                            <div >
                                <div className='first_div'>Numar Articol</div>
                                <div className='second_div'> {post.nr_articol}</div>
                            </div>

                            {post.descriptori !== '' && <div>
                                <div className='first_div'>Descriptori</div>
                                <div className='second_div'>{post.descriptori}</div>
                            </div>}

                            {post.limba!=='' && <div>
                                <div className='first_div'>Limba de redactare</div>
                                <div className='second_div'>{post.limba}</div>
                            </div>}

                            {post.paginatie!=='' && <div>
                                <div className='first_div'>Paginatie</div> 
                                <div className='second_div'>{post.paginatie}</div>
                            </div>}

                            {post.cuvinte_cheie !=='' && <div>
                                <div className='first_div'>Cuvinte Cheie</div>
                                <div className='second_div'>{post.cuvinte_cheie}</div>
                            </div>}

                            {post.cuvinte_cheie_en !=='' && <div>
                                <div className='first_div'>Cuvinte Cheie EN</div>
                                <div className='second_div'>{post.cuvinte_cheie_en}</div>
                            </div>}

                            {/* <p>{post.text_articol}</p> */}

                            {post.rezumat !=='' && <div>
                                <div className='first_div'>Rezumat</div>
                                <div className='second_div'>{post.rezumat} </div>
                            </div>}

                            {post.rezumat_en !=='' && <div>
                                <div className='first_div'>Rezumat EN</div>
                                <div className='second_div'>{post.rezumat_en}</div>
                            </div>}

                            {post.numar_pagini!=='' && <div>
                                <div className='first_div'>Numar Pagini</div>
                                <div className='second_div'>{post.numar_pagini}</div>
                            </div>}
                        </div>
                    </div>
            </div>
            )
        }else{
            return(
                <div className='post_page'>
                    Articolul nu a fost gasit
                    
                    {console.log('indice arhiva===',indice_arhiva)}
                    {console.log()}
                </div>
            )
        }
    }
    else{
        return(
            <div>Loading...</div>
        )
    }
}

export default PostPage
