import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import axios from 'axios';
import './EditAutor.css'

function EditAutor() {
    const user = useSelector(state => state.user);
    const [autor, setAutor]=useState({})
    const [nume_autor, setNumeAutor]=useState('')
    const [id_autor, setIdAutor]=useState('')
    const [articole_autor, setArticoleAutor]=useState('')
    const [show_confirm_msg_nume,setShowConfirmMsgNume]=useState(false)
    const [show_confirm_msg_articole,setShowConfirmMsgArticole]=useState(false)

    useEffect(()=>{
        let id_din_url=window.location.href.split('/')
        id_din_url=parseInt(id_din_url[id_din_url.length-1])
        axios.get('/api/arhiva/getAuthors')
        .then( (res)=>{
            if(res.data.success){
                setAutor(res.data.autori[id_din_url-1])
            }
            else{
                console.log('erroare')
            }
        })
    },[])

    useEffect(()=>{
        if(autor != {}){
            setNumeAutor(autor.nume_autor)
            setIdAutor(autor.id_autor)
            setArticoleAutor(autor.articole_autor)
            // setTextArticol(articol.text_articol)
        }
    },[autor])

    function onSubmitNumeAutor(event){
        event.preventDefault();
        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        let autor_editat={
            '_id':autor._id,
            id_autor:id_autor,
            nume_autor:nume_autor,
            articole_autor:articole_autor
        }

        axios.post('/api/blog/editare_nume_autor_si_dependente', autor_editat)
        .then(
            (()=>{
                alert('Articolul a fost editat cu succes !');
                console.log('articolul a fost editat cu succes')
                window.location.reload();
            })()
        )
    }



    function onSubmitArticole(event){
        event.preventDefault();
        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in first');
        }

        let autor_editat={
            '_id':autor._id,
            id_autor:id_autor,
            nume_autor:nume_autor,
            articole_autor:articole_autor
        }

        console.log('am rulat onsubmit articole')
        
        axios.post('/api/blog/adaug_autor_in_autori_articol',autor_editat)
        .then(
            setTimeout(()=>{
                axios.post('/api/blog/editare_autor', autor_editat)
                .then(
                    (()=>{
                        alert('Articolul a fost editat cu succes !');
                        console.log('articolul a fost editat cu succes')
                        window.location.reload();
                    })()
                )
            },5000)
        )
    }


    function handleSetState(mySetFunction, text_input, event) {
        // const value = event.currentTarget.value;
        console.log('mySetFunction===',mySetFunction)
        mySetFunction(text_input);
    }
    
    const onKeyDown = function(e, id_element, mySetFunction) {
        console.log('id_element===',id_element)
        if (e.keyCode === 8) {
            let text_input=''
            setTimeout(() => {
                text_input=document.getElementById(id_element).value
                handleSetState(mySetFunction, text_input, e)
            }, 100);
        }
    }

    const handleCancelMsgNume=(e)=>{
        e.preventDefault();
        setShowConfirmMsgNume(false);
    }

    const handleCancelMsgArticole=(e)=>{
        e.preventDefault();
        setShowConfirmMsgArticole(false);
    }
    
    const handleConfirmMsgNume = (e) => {
        e.preventDefault();
        setShowConfirmMsgNume(true);
    }

    const handleConfirmMsgArticole = (e) => {
        e.preventDefault();
        setShowConfirmMsgArticole(true);
    }

    return (
        <div className='edit_autor_container'>


        <form onSubmit={handleConfirmMsgNume}>
            
            {/* <div className='id_autor_div'>
                <label className='id_autor_label' >ID Autor</label>
                <input 
                    className='id_autor_input'
                    id='id_autor_id'
                    size="large"
                    htmlType="submit"
                    onChange={(event)=>setIdAutor(event.target.value)}
                    onKeyDown={(event)=>{onKeyDown(event,'id_autor_id',setIdAutor)}}
                    defaultValue={autor.id_autor}
                    type="text" 
                    name="id_autor"
                />
            </div> */}


            <div className='nume_autor_div'>
                <label className='nume_autor_label' >Nume Autor</label>
                <input 
                    className='nume_autor_input'
                    id='nume_autor_id'
                    size="large"
                    htmlType="submit"
                    onChange={(event)=>setNumeAutor(event.target.value)}
                    onKeyDown={(event)=>{onKeyDown(event,'nume_autor_id',setNumeAutor)}}
                    defaultValue={autor.nume_autor}
                    type="text" 
                    name="nume_autor"
                />
            </div>

            <div className='editare_submit_div'>
                <button
                    htmlType="submit"
                    className="editare_submit"
                    onSubmit={handleConfirmMsgNume}>
                    SUBMIT
                </button>
            </div>



            {show_confirm_msg_nume && (
            <div className='mesaj_confirmare'> 
                <br/> 
                <p className='esti_sigur'>Esti sigur ca vrei sa salvezi modificarile? </p> {" "}
                <button type='submit' className='submit_raspuns' onClick={(event)=>onSubmitNumeAutor(event)}> 
                    DA
                </button>

                <button className='submit_raspuns' onClick={(event)=>handleCancelMsgNume(event)}> 
                    NU
                </button>
            </div> 
            )}
        </form>


<br/>
<br/>
<br/>
<br/>
<br/>




        <form onSubmit={handleConfirmMsgArticole}>

            <div className='articole_autor_div'>
                <label className='articole_autor_label' >Articole Autor</label>
                <input 
                    className='articole_autor_input'
                    id='articole_autor_id'
                    size="large"
                    htmlType="submit"
                    onChange={(event)=>setArticoleAutor(event.target.value)}
                    onKeyDown={(event)=>{onKeyDown(event,'articole_autor_id',setArticoleAutor)}}
                    defaultValue={autor.articole_autor}
                    type="text" 
                    name="articole_autor"
                />

                <div className='editare_submit_div'>
                    <button
                        htmlType='submit'
                        className='editare_submit'
                        onSubmit={handleConfirmMsgArticole}>
                        SUBMIT
                    </button>
                </div>
            </div>

            {show_confirm_msg_articole && (
                <div className='mesaj_confirmare'>
                    <br/>

                    <p className='esti_sigur'>Esti sigur ca vrei sa salvezi modificarile? </p> {" "}
                    <button type='submit' className='submit_raspuns' onClick={(event)=>onSubmitArticole(event)}> 
                        DA
                    </button>
                    <button className='submit_raspuns' onClick={(event)=>handleCancelMsgArticole(event)}> 
                        NU
                    </button>
                </div>
            )}
        </form>
        </div>
    )
} export default EditAutor


